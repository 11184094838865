import { Timestamp } from 'firebase/firestore';
import create from 'zustand';

import { TODO } from './global.types';

export interface GlobalStoreType {
  isWindowTooSmall: boolean;
  setWindowTooSmall: (state: boolean) => void;

  isCommandOpen: boolean;
  setCommandOpen: (state: boolean) => void;

  isNotificationsOpen: boolean;
  setNotificationsOpen: (state: boolean) => void;

  isGenerateOpen: boolean;
  setGenerateOpen: (state: boolean) => void;

  generateOptions: Record<string, any>;
  setGenerateOptions: (options: Record<string, any>) => void;

  isDescribeOpen: boolean;
  setDescribeOpen: (state: boolean) => void;

  describeOptions: Record<string, any>;
  setDescribeOptions: (options: Record<string, any>) => void;

  inviteId: string;
  setInviteId: (id: string) => void;

  isInviteOpen: boolean;
  setInviteOpen: (state: boolean) => void;

  timestamp: Timestamp;
  setTimestamp: (timestamp: Timestamp) => void;

  isVoiceoverOpen: boolean;
  setVoiceoverOpen: (state: boolean) => void;

  voiceoverRef: TODO;
  setVoiceoverRef: (ref: TODO) => void;

  isCharacterOpen: boolean;
  setCharacterOpen: (state: boolean) => void;

  voiceRef: TODO;
  setVoiceRef: (ref: TODO) => void;

  voiceBrandRef: TODO;
  setVoiceBrandRef: (ref: TODO) => void;

  isCollectionOpen: boolean;
  setCollectionOpen: (state: boolean) => void;

  collectionRef: TODO;
  setCollectionRef: (ref: TODO) => void;

  isWorkspacesOpen: boolean;
  setWorkspacesOpen: (state: boolean) => void;

  isWelcomeOpen: boolean;
  setWelcomeOpen: (state: boolean) => void;

  continueThread: boolean;
  setContinueThread: (state: boolean) => void;
}

const useGlobalStore = create<GlobalStoreType>(
  (
    set: (
      partial: (store: GlobalStoreType) => Partial<GlobalStoreType>
    ) => void,
    // eslint-disable-next-line
    get: () => GlobalStoreType
  ) => {
    return {
      isWindowTooSmall: false,
      setWindowTooSmall: state => {
        set(() => ({
          isWindowTooSmall: state,
        }));
      },

      isCommandOpen: false,
      setCommandOpen: state => {
        set(() => ({
          isCommandOpen: state,
        }));
      },

      isNotificationsOpen: false,
      setNotificationsOpen: state => {
        set(() => ({
          isNotificationsOpen: state,
        }));
      },

      isGenerateOpen: false,
      setGenerateOpen: state => {
        set(() => ({
          isGenerateOpen: state,
        }));
      },

      generateOptions: null,
      setGenerateOptions: options => {
        set(() => ({
          generateOptions: options,
        }));
      },

      isDescribeOpen: false,
      setDescribeOpen: state => {
        set(() => ({
          isDescribeOpen: state,
        }));
      },

      describeOptions: null,
      setDescribeOptions: options => {
        set(() => ({
          describeOptions: options,
        }));
      },

      inviteId: null,
      setInviteId: id => {
        set(() => ({
          inviteId: id,
        }));
      },

      isInviteOpen: false,
      setInviteOpen: state => {
        set(() => ({
          isInviteOpen: state,
        }));
      },

      timestamp: Timestamp.now(),
      setTimestamp: timestamp => {
        set(() => ({
          timestamp,
        }));
      },

      isVoiceoverOpen: false,
      setVoiceoverOpen: state => {
        set(() => ({
          isVoiceoverOpen: state,
        }));
      },

      voiceoverRef: null,
      setVoiceoverRef: ref => {
        set(() => ({
          voiceoverRef: ref,
        }));
      },

      voiceBrandRef: null,
      setVoiceBrandRef: ref => {
        set(() => ({
          voiceBrandRef: ref,
        }));
      },

      isCharacterOpen: false,
      setCharacterOpen: state => {
        set(() => ({
          isCharacterOpen: state,
        }));
      },

      voiceRef: null,
      setVoiceRef: ref => {
        set(() => ({
          voiceRef: ref,
        }));
      },

      isCollectionOpen: false,
      setCollectionOpen: state => {
        set(() => ({
          isCollectionOpen: state,
        }));
      },

      collectionRef: null,
      setCollectionRef: ref => {
        set(() => ({
          collectionRef: ref,
        }));
      },

      isWorkspacesOpen: false,
      setWorkspacesOpen: state => {
        set(() => ({
          isWorkspacesOpen: state,
        }));
      },

      isWelcomeOpen: false,
      setWelcomeOpen: state => {
        set(() => ({
          isWelcomeOpen: state,
        }));
      },

      continueThread: false,
      setContinueThread: state => {
        set(() => ({
          continueThread: state,
        }));
      },
    };
  }
);

export default useGlobalStore;
