import styled from 'styled-components';

import { pxToRems } from 'utils/styles/mixins';

export const Entry = styled.div`
  display: grid;
  gap: 3px;
`;

export const Icon = styled.div`
  width: 21px;
  height: 21px;
`;

export const Li = styled.li``;

export const Inner = styled.div`
  height: ${pxToRems(39)};
  gap: ${pxToRems(8)};
`;
