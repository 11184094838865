import { User } from 'lucide-react';
import React, { useEffect } from 'react';

import useUsersStore from 'store/users';

import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { cn } from '@/lib/utils';

export const WsAvatars = (props: { uids: string[] }) => {
  const { uids } = props;
  const { users, getUser } = useUsersStore();
  useEffect(() => {
    uids.forEach(email => {
      getUser(email);
    });
  }, [getUser, uids]);
  return (
    <div className={'flex -space-x-2 mt-1'}>
      {uids.map(uid => {
        const user = users.find(u => u.id === uid || u.email === uid);
        const photoURL = user?.photoURL;
        const name = user?.name || user?.displayName || uid;
        return (
          <Tooltip key={uid} delayDuration={100}>
            <TooltipTrigger className={'hover:z-10'}>
              <WsAvatar
                {...{
                  size: 'small',
                  photoURL,
                  name,
                }}
              />
            </TooltipTrigger>
            {name && <TooltipContent>{name}</TooltipContent>}
          </Tooltip>
        );
      })}
    </div>
  );
};

export const WsAvatar = (props: {
  photoURL?: string;
  name?: string;
  size?: 'default' | 'small';
}) => {
  const { photoURL, name, size = 'default' } = props;
  return (
    <Avatar className={cn(size === 'small' ? 'w-7 h-7' : '')}>
      <AvatarImage src={photoURL} alt={`@${name}`} />
      <AvatarFallback className={cn(size === 'small' ? 'text-sm' : '')}>
        {name ? (
          name
            .split(' ')
            .map(word => word[0])
            .filter(char => /^[A-Za-z]$/.test(char))
            .filter(
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              (char, index, arr) =>
                !/^(V[0-9]|VO)$/i.test(name.split(' ')[index])
            )
            .join('')
            .toUpperCase()
        ) : (
          <User className={cn(size === 'small' ? 'w-3.5 h-3.5' : 'w-4 h-4')} />
        )}
      </AvatarFallback>
    </Avatar>
  );
};
