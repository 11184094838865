import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import React from 'react';

import CharacterFormView from 'containers/CharactersPage/CharacterFormView';
import CollectionFormView from 'containers/CollectionsPage/CollectionFormView';
import VoiceoverFormView from 'containers/VoiceoversPage/VoiceoverFormView';
import { useGlobalStore } from 'store';

const SharedForms = () => {
  const router = useRouter();
  const wsId = router.query.wsId as string;
  const { data: session } = useSession();
  const uid = session?.user?.id;

  const {
    isVoiceoverOpen,
    setVoiceoverOpen,
    voiceoverRef,
    setVoiceoverRef,
    isCharacterOpen,
    setCharacterOpen,
    voiceRef,
    setVoiceRef,
    voiceBrandRef,
    setVoiceBrandRef,
    isCollectionOpen,
    setCollectionOpen,
    collectionRef,
    setCollectionRef,
  } = useGlobalStore();

  return (
    <>
      {isVoiceoverOpen && uid && (
        <VoiceoverFormView
          wsId={wsId}
          uid={uid}
          open={isVoiceoverOpen}
          onOpenChange={() => {
            setVoiceoverOpen(false);
            setVoiceoverRef(null);
          }}
          voiceoverRef={voiceoverRef}
        />
      )}
      {isCharacterOpen && uid && (
        <CharacterFormView
          wsId={wsId}
          uid={uid}
          open={isCharacterOpen}
          onOpenChange={() => {
            setCharacterOpen(false);
            setVoiceBrandRef(null);
            setVoiceRef(null);
          }}
          voiceBrandRef={voiceBrandRef}
          voiceRef={voiceRef}
        />
      )}
      {isCollectionOpen && uid && (
        <CollectionFormView
          open={isCollectionOpen}
          onOpenChange={() => {
            setCollectionOpen(false);
            setCollectionRef(null);
          }}
          collectionRef={collectionRef}
        />
      )}
    </>
  );
};

export default SharedForms;
