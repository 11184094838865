import { useCallback, useEffect, useState } from 'react';

import useCommon from 'hooks/useCommon';
import { TODO } from 'store/global.types';
import useImaginationsStore, {
  imaginationsCollection,
} from 'store/imaginations';
import useProjectsStore from 'store/projects';
import useThreadsStore from 'store/threads';
import useVoicesStore from 'store/voices';
import { ROUTES } from 'utils/routes';

const useProjects = (props: { wsId: string; uid: string; pId: string }) => {
  const { router } = useCommon();
  const { wsId, pId } = props;
  const { projects, getProject, updateProject } = useProjectsStore();
  const { getThread } = useThreadsStore();
  const { getVoice } = useVoicesStore();
  const { getImagination } = useImaginationsStore();
  const [recents, setRecents] = useState<TODO[]>([]);

  const [project, setProject] = useState<TODO>(null);

  const onArchive = useCallback(async () => {
    if (!project) return;
    updateProject(project.ref, {
      isArchive: true,
    });
    router.push(ROUTES.PROJECTS.replace('[wsId]', wsId));
  }, [project, router, updateProject, wsId]);

  const fetchRecents = useCallback(async () => {
    const results = [];
    const recentPaths = [
      ...(project?.imaginations || []),
      ...(project?.imaginationsIterations || []),
      ...(project?.threads || []),
      ...(project?.projects || []),
      ...(project?.brands || []),
      ...(project?.voiceovers || []),
      ...(project?.characters || []),
    ];
    for (const path of recentPaths) {
      const segments = path.split('/');
      const projectWsId = segments[1];
      const segment = segments[2];
      const projectId = segments[3];
      if (segment === 'threads') {
        const thread = await getThread(projectWsId, projectId);
        results.push({ type: 'thread', thread, path });
      } else if (segment === 'voices') {
        const voice = await getVoice(projectWsId, projectId);
        results.push({ type: 'voice', voice, path });
      } else if (segment === imaginationsCollection) {
        const imagination = await getImagination(projectWsId, projectId);
        results.push({ type: 'imagination', imagination, path });
      } else {
        results.push({ type: '', path });
      }
    }
    setRecents(results);
  }, [
    getImagination,
    getThread,
    getVoice,
    project?.brands,
    project?.characters,
    project?.imaginations,
    project?.imaginationsIterations,
    project?.projects,
    project?.threads,
    project?.voiceovers,
  ]);

  const fetchProject = useCallback(async () => {
    const project = await getProject(wsId, pId);
    if (project) setProject(project);
  }, [getProject, pId, wsId]);

  useEffect(() => {
    if (project) fetchRecents();
  }, [fetchRecents, project]);

  useEffect(() => {
    fetchProject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects]);

  useEffect(() => {
    fetchProject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    project,
    onArchive,
    recents,
    updateProject,
  };
};
export default useProjects;
