import styled from 'styled-components';

import { setVh } from 'utils/styles/mixins';

export const Wrapper = styled.div<{
  $isFullWidth: boolean;
}>`
  ${({ $isFullWidth }) =>
    !$isFullWidth &&
    `
      max-width: 987px;
      margin-inline: auto;
    `}
  display: flex;
  flex-direction: column;
  min-height: ${setVh(100)};
`;
