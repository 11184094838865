import { AnimatePresence, motion } from 'framer-motion';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import { useHover, useLocalStorage } from 'usehooks-ts';

import Sidebar, { SidebarProps } from 'components/Sidebar/Sidebar';
import SidebarAccount from 'components/Sidebar/Sidebar.account';
import SidebarDashboard from 'components/Sidebar/Sidebar.dashboard';
import {
  isAccountPage,
  isDashboardPage,
  isHeaderPage,
  isImagitnationPage,
  isThreadPage,
} from 'utils/routes';
import { pageMotionProps } from 'utils/styles/animations';

import * as Styled from './PageView.styles';

import { ScrollArea } from '@/components/ui/scroll-area';
import { cn } from '@/lib/utils';

export interface PageViewProps {
  uid?: string;
  sidebarProps?: Omit<SidebarProps, 'activeId'>;
  children: React.ReactNode | React.ReactNode[];
}

const defaultProps: Partial<PageViewProps> = {};

const PageView: React.FC<PageViewProps> = ({ uid, sidebarProps, children }) => {
  const router = useRouter();
  const hoverRef = useRef(null);
  const isHover = useHover(hoverRef);
  const [isSidebarOpen] = useLocalStorage('isSidebarOpen', true);

  const [gridCols, setGridCols] = useState('grid-cols-[0px_0px_1fr]');

  useEffect(() => {
    const newGridCols = sidebarProps
      ? isSidebarOpen
        ? 'lg:grid-cols-[230px_1fr]'
        : 'lg:grid-cols-[0px_1fr]'
      : 'lg:grid-cols-[0px_1fr]';

    setGridCols(newGridCols);
  }, [sidebarProps, isSidebarOpen]);

  return (
    <div
      className={cn('grow grid items-start h-full transition-all', gridCols)}
    >
      <div
        className={cn(
          'hidden lg:grid sticky top-14 self-start overflow-hidden h-full max-h-[calc(100vh-3.5rem)] grid-rows-[auto_1fr_auto] ',
          isHover ? 'opacity-60' : 'opacity-100'
        )}
      >
        {sidebarProps && <SidebarDashboard />}
        <ScrollArea>{sidebarProps && <Sidebar {...sidebarProps} />}</ScrollArea>
        {sidebarProps && uid && <SidebarAccount {...{ uid }} />}
      </div>

      <AnimatePresence mode="wait">
        <motion.div {...pageMotionProps}>
          <Styled.WrapperInner
            className={cn(
              'w-full min-h-[calc(100vh-3.5rem)]',
              !sidebarProps ? 'justify-center' : 'px-8 pt-8',
              !isHeaderPage(router) ? '' : 'pb-8',
              isThreadPage(router) ? 'pt-4 pl-4 pb-0' : '',
              isImagitnationPage(router)
                ? 'pt-0 min-h-[calc(100vh-3.5rem)]'
                : '',
              isDashboardPage(router) || isAccountPage(router)
                ? 'bg-muted/35'
                : ''
            )}
          >
            {children}
          </Styled.WrapperInner>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

PageView.defaultProps = defaultProps;

export default PageView;
