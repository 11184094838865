import React, { useEffect } from 'react';

import useAccount from 'hooks/useAccount';
import useCommon from 'hooks/useCommon';
import { useGlobalStore } from 'store';
import useUsersStore from 'store/users';
import { ROUTES } from 'utils/routes';

import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';

const InviteDialog = () => {
  const { router } = useCommon();
  const { inviteId, isInviteOpen, setInviteId, setInviteOpen } =
    useGlobalStore();
  const { onUpdateMemberStatus } = useAccount();
  const { setWsIdLocal } = useCommon();
  const { users, getUser } = useUsersStore();
  const user = users.find(user => user.id === inviteId);

  useEffect(() => {
    getUser(inviteId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const name =
    user?.organizationName || user?.name || user?.displayName || 'a workspace';

  return (
    <Dialog
      open={isInviteOpen}
      onOpenChange={open => {
        setInviteId(null);
        setInviteOpen(open);
      }}
    >
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Workspace Invite</DialogTitle>
          <DialogDescription
            dangerouslySetInnerHTML={{
              __html: `You have been invited to join <b>${name}</b>.`,
            }}
          ></DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <Button
            variant={'outline'}
            onClick={() => {
              onUpdateMemberStatus(inviteId, 'declined');
              setInviteOpen(false);
              setInviteId(null);
            }}
          >
            Decline
          </Button>
          <Button
            onClick={async () => {
              await onUpdateMemberStatus(inviteId, 'accepted');
              setWsIdLocal(inviteId);
              await router.replace(ROUTES.WORKSPACE);
              router.reload();
            }}
          >
            Accept
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
export default InviteDialog;
