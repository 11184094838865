export const sidebar = {
  steps: [
    {
      id: 'account_org',
      icon: 'building',
      label: 'Organization',
    },
    {
      id: 'account_profile',
      icon: 'circle-user',
      label: 'Profile',
    },
    {
      id: 'account_team',
      icon: 'users',
      label: 'Team',
    },
    // {
    //   id: 'account_integrations',
    //   icon: 'blocks',
    //   label: 'Integrations',
    // },
    {
      id: 'account_billing',
      icon: 'receipt',
      label: 'Subscription',
    },
    {
      id: 'account_usage',
      icon: 'bar-chart-2',
      label: 'Usage',
    },
  ],
};
