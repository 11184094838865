import { useCycle } from 'framer-motion';
import { ALargeSmall, MoveHorizontal } from 'lucide-react';
import React, { useEffect } from 'react';
import { useLocalStorage } from 'usehooks-ts';

import useCommon from 'hooks/useCommon';
import { isThreadPage } from 'utils/routes';

import { Button } from '@/components/ui/button';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectSeparator,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { useSvgIcon } from 'growl/hooks';

const ThreadsMenu = () => {
  const { router } = useCommon();

  const [threadsView, setThreadsView] = useLocalStorage(
    'threadsView',
    'narrow'
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [threadsFontSize, setThreadsFontSize] = useLocalStorage(
    'threadsFontSize',
    'text-sm'
  );

  const [threadsFontSizeCycle, setThreadsFontSizeCycle] = useCycle(
    'text-sm',
    'text-sm-b',
    'text-base',
    'text-lg',
    'text-xl'
  );

  useEffect(() => {
    setThreadsFontSize(threadsFontSizeCycle);
  }, [setThreadsFontSize, threadsFontSizeCycle]);

  useEffect(() => {
    setThreadsFontSizeCycle(
      ['text-sm', 'text-sm-b', 'text-base', 'text-lg', 'text-xl'].indexOf(
        threadsFontSize
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [threadModel, setThreadModel] = useLocalStorage(
    'threadModel',
    'openai'
  );

  const OpenAI = useSvgIcon('openai');
  const Anthropic = useSvgIcon('anthropic');
  const Meta = useSvgIcon('meta');

  if (!isThreadPage(router)) return <></>;
  return (
    <div className={'flex items-center gap-1'}>
      <Select
        defaultValue={threadModel}
        value={threadModel}
        onValueChange={value => {
          setThreadModel(value);
        }}
      >
        <SelectTrigger className="rounded-full">
          <SelectValue placeholder="Models" defaultValue={threadModel} />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectItem value="openai">
              <div className="flex items-center gap-1.5">
                <OpenAI.SvgIcon className="w-3.5 h-3.5" /> OpenAI
              </div>
            </SelectItem>
            <SelectItem value="anthropic" className="flex">
              <div className="flex items-center gap-1.5">
                <Anthropic.SvgIcon className="w-3.5 h-3.5" /> Anthrop\c
              </div>
            </SelectItem>
            <SelectItem value="meta" className="flex">
              <div className="flex items-center gap-1.5">
                <Meta.SvgIcon className="w-3.5 h-3.5" /> Meta
              </div>
            </SelectItem>
          </SelectGroup>
          <SelectSeparator />
          <SelectGroup>
            <SelectLabel className="text-xs">
              AI models designed to spend more <br /> time thinking before they
              respond.
            </SelectLabel>
            <SelectItem value="openai-o1-mini">
              <div className="flex items-center gap-1.5">
                <OpenAI.SvgIcon className="w-3.5 h-3.5" /> OpenAI (o1 mini)
              </div>
            </SelectItem>
            <SelectItem value="openai-o1-preview">
              <div className="flex items-center gap-1.5">
                <OpenAI.SvgIcon className="w-3.5 h-3.5" /> OpenAI (o1 preview)
              </div>
            </SelectItem>
          </SelectGroup>
        </SelectContent>
      </Select>

      <Tooltip delayDuration={100}>
        <TooltipTrigger asChild>
          <Button
            variant={'ghost'}
            size={'icon'}
            className={'rounded-full'}
            onClick={() => setThreadsFontSizeCycle()}
          >
            <ALargeSmall className={'h-4 w-4'} />
          </Button>
        </TooltipTrigger>
        <TooltipContent side={'bottom'}>
          <p>Font settings</p>
        </TooltipContent>
      </Tooltip>

      <Tooltip delayDuration={100}>
        <TooltipTrigger asChild>
          <Button
            variant={'ghost'}
            size={'icon'}
            className={'rounded-full'}
            onClick={() =>
              setThreadsView(threadsView === 'wide' ? 'narrow' : 'wide')
            }
          >
            <MoveHorizontal className={'h-4 w-4'} />
          </Button>
        </TooltipTrigger>
        <TooltipContent side={'bottom'}>
          <p>Content width</p>
        </TooltipContent>
      </Tooltip>
    </div>
  );
};
export default ThreadsMenu;
