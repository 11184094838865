import _ from 'lodash';
import { Archive, Bolt, Plus, Shapes } from 'lucide-react';
import React, { useEffect } from 'react';
import { useLocalStorage } from 'usehooks-ts';

import useCommon from 'hooks/useCommon';
import useBrandsStore from 'store/brands';
import { isBrandPage, isBrandsPage, ROUTES } from 'utils/routes';
import { colors } from 'utils/styles/theme';

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip';

const BrandsMenu = (props: {
  uid: string;
  breadcrumbs?: boolean;
  actions?: boolean;
}) => {
  const { uid, breadcrumbs, actions } = props;
  const { router, wsId } = useCommon();
  const bId = router.query.bId as string;

  const handleCreate = () => {
    router.push(ROUTES.BRANDS_CREATE.replace('[wsId]', `${router.query.wsId}`));
  };

  if (!isBrandsPage(router) && !isBrandPage(router)) return <></>;
  return (
    <>
      {breadcrumbs && (
        <>
          <Separator orientation="vertical" className={'mx-1 h-5'} />
          <Breadcrumb>
            <BreadcrumbList>
              {(isBrandsPage(router) || isBrandPage(router)) && (
                <>
                  <BreadcrumbItem>
                    <span
                      className={'text-sm text-foreground cursor-pointer'}
                      onClick={() => {
                        router.push(ROUTES.BRANDS.replace('[wsId]', wsId));
                      }}
                    >
                      Brands
                    </span>
                  </BreadcrumbItem>
                </>
              )}
              {bId && isBrandPage(router) && (
                <BrandCrumb {...{ wsId, bId, uid }} />
              )}
            </BreadcrumbList>
          </Breadcrumb>
        </>
      )}
      {actions && (
        <>
          {isBrandsPage(router) && (
            <div className={'flex items-center gap-1'}>
              <Button type="button" onClick={handleCreate} size={'sm'}>
                <Plus className={'h-4 w-4 mr-2'} />
                New brand
              </Button>
            </div>
          )}
          {bId && isBrandPage(router) && <BrandMenu {...{ wsId, bId, uid }} />}
        </>
      )}
    </>
  );
};
export default BrandsMenu;

const BrandCrumb = (props: { wsId; bId; uid }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { wsId, bId, uid } = props;
  const { brands, getBrand } = useBrandsStore();
  const brand = brands?.find(brand => brand.id === bId);

  useEffect(() => {
    getBrand(wsId, bId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <BreadcrumbSeparator />
      <BreadcrumbItem>
        <span className={'text-sm text-foreground'}>{brand?.name}</span>
      </BreadcrumbItem>
    </>
  );
};

const BrandMenu = (props: { wsId; bId; uid }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { wsId, bId, uid } = props;
  const { router } = useCommon();

  const { brands, getBrand, updateBrand } = useBrandsStore();
  const brand = brands?.find(brand => brand.id === bId);

  const [brandView, setBrandView] = useLocalStorage(
    `brandView-${bId}`,
    'brand'
  );

  useEffect(() => {
    getBrand(wsId, bId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={'flex items-center gap-1'}>
      <Button
        type="button"
        onClick={() => {
          router.push(
            ROUTES.PROJECTS_CREATE.replace('[wsId]', `${router.query.wsId}`)
          );
        }}
        size={'sm'}
      >
        <Plus className={'h-4 w-4 mr-2'} />
        New project
      </Button>
      <Separator orientation="vertical" className={'mx-1 h-5'} />
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            variant={brandView === 'brand' ? 'secondary' : 'ghost'}
            size={'icon'}
            onClick={() => {
              setBrandView('brand');
            }}
            className={'rounded-full'}
          >
            <Shapes className={'h-4 w-4'} />
          </Button>
        </TooltipTrigger>
        <TooltipContent>
          <p>Brand Projects</p>
        </TooltipContent>
      </Tooltip>

      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            variant={brandView === 'settings' ? 'secondary' : 'ghost'}
            size={'icon'}
            onClick={() => {
              setBrandView('settings');
            }}
            className={'rounded-full'}
          >
            <Bolt className={'h-4 w-4'} />
          </Button>
        </TooltipTrigger>
        <TooltipContent>
          <p>Brand Settings</p>
        </TooltipContent>
      </Tooltip>

      <Separator orientation="vertical" className={'mx-1 h-5'} />
      <AlertDialog>
        <AlertDialogTrigger>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant="ghost"
                size="icon"
                className={'rounded-full'}
                style={{ color: colors.eurykaRed }}
              >
                <Archive className={'h-4 w-4'} />
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>Archive Brand</p>
            </TooltipContent>
          </Tooltip>
        </AlertDialogTrigger>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Archive brand</AlertDialogTitle>
            <AlertDialogDescription>
              {_.sample([
                'Brand time capsule awaiting launch!',
                'Ready to enshrine your brand legacy?',
                'Bid farewell and archive the brand voyage?',
              ])}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={() => {
                updateBrand(brand.ref, {
                  isArchive: true,
                });
                router.push(ROUTES.BRANDS.replace('[wsId]', wsId));
              }}
            >
              Continue
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};
