import { arrayRemove, arrayUnion } from 'firebase/firestore';
import _ from 'lodash';
import {
  Archive,
  Blend,
  BookmarkPlus,
  CheckIcon,
  Construction,
  Download,
  LayoutGrid,
  LibraryBig,
  Rows3,
} from 'lucide-react';
import React, { useCallback } from 'react';
import { useLocalStorage } from 'usehooks-ts';

import useCommon from 'hooks/useCommon';
import { useGlobalStore } from 'store';
import useCollectionsStore from 'store/collections';
import useImaginationsStore from 'store/imaginations';
import useProjectsStore from 'store/projects';
import useUsersStore from 'store/users';
import { ROUTES } from 'utils/routes';
import { colors } from 'utils/styles/theme';

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Separator } from '@/components/ui/separator';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { cn } from '@/lib/utils';

export interface ImaginationMenuProps {
  uid: string;
}

const defaultProps: Partial<ImaginationMenuProps> = {};

const ImaginationMenu: React.FC<ImaginationMenuProps> = ({ uid }) => {
  const { router, wsId } = useCommon();
  const iId = router.query.iId as string;
  const { users } = useUsersStore();
  const { setCollectionOpen } = useGlobalStore();

  const { imaginations, updateImagination } = useImaginationsStore();
  const imagination = imaginations.find(i => i.id === iId);

  const { projects, updateProject, getProjectPrivilege } = useProjectsStore();
  const { collections, updateCollection } = useCollectionsStore();

  const onArchive = useCallback(() => {
    if (!imagination) return;
    updateImagination(imagination.ref, { isArchive: true });
    router.push(ROUTES.IMAGINATIONS.replace('[wsId]', wsId));
  }, [imagination, router, updateImagination, wsId]);

  const [iterationsView, setIterationsView] = useLocalStorage(
    'iterationsView',
    'rows'
  );

  const ws = users.find(u => u.id === wsId);
  const user = users.find(u => u.id === uid);

  if (!imagination) return <></>;

  const projectsFiltered = projects.filter(project => {
    const privilege = getProjectPrivilege(
      wsId,
      project.id,
      uid,
      ws?.email,
      user?.email
    );
    return privilege.canView;
  });

  return (
    <div className={'flex items-center gap-1'}>
      {iterationsView === 'grid' && (
        <>
          <Tooltip delayDuration={100}>
            <TooltipTrigger asChild>
              <Button
                variant={'ghost'}
                size="icon"
                className={'rounded-full'}
                onClick={() => {
                  // TODO
                }}
              >
                <Download className="h-4 w-4" />
              </Button>
            </TooltipTrigger>
            <TooltipContent side={'bottom'} align={'start'} className={'flex'}>
              <Construction className="h-4 w-4 mr-2" /> Download all iterations
            </TooltipContent>
          </Tooltip>
          <Tooltip delayDuration={100}>
            <TooltipTrigger asChild>
              <Button
                variant={'ghost'}
                size="icon"
                className={'rounded-full'}
                onClick={() => {
                  // TODO
                }}
              >
                <Blend className="h-4 w-4" />
              </Button>
            </TooltipTrigger>
            <TooltipContent side={'bottom'} align={'start'} className={'flex'}>
              <Construction className="h-4 w-4 mr-2" /> Blend iterations
            </TooltipContent>
          </Tooltip>
        </>
      )}
      <Tooltip delayDuration={100}>
        <TooltipTrigger asChild>
          <Button
            variant={iterationsView === 'grid' ? 'secondary' : 'ghost'}
            size="icon"
            className={'rounded-full'}
            onClick={() => setIterationsView('grid')}
          >
            <LayoutGrid className="h-4 w-4 " />
          </Button>
        </TooltipTrigger>
        <TooltipContent side={'bottom'}>
          <p>Grid view</p>
        </TooltipContent>
      </Tooltip>

      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            variant={iterationsView === 'rows' ? 'secondary' : 'ghost'}
            size="icon"
            className={'rounded-full'}
            onClick={() => setIterationsView('rows')}
          >
            <Rows3 className="h-4 w-4 " />
          </Button>
        </TooltipTrigger>
        <TooltipContent>
          <p>Timeline view</p>
        </TooltipContent>
      </Tooltip>
      <Separator orientation="vertical" className={'mx-1 h-5'} />
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button variant="ghost" size="icon" className={'rounded-full'}>
                <BookmarkPlus className="h-4 w-4 " />
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>Organise</p>
            </TooltipContent>
          </Tooltip>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuGroup>
            <DropdownMenuSub>
              <DropdownMenuSubTrigger>
                <LibraryBig className={'h-4 w-4 mr-2'} />
                Add to Projects
              </DropdownMenuSubTrigger>
              <DropdownMenuPortal>
                <DropdownMenuSubContent>
                  {projectsFiltered.length === 0 ? (
                    <DropdownMenuItem
                      onClick={() => {
                        router.push(
                          ROUTES.PROJECTS_CREATE.replace('[wsId]', wsId)
                        );
                      }}
                    >
                      Create New Project
                    </DropdownMenuItem>
                  ) : (
                    <Command>
                      {projectsFiltered.length > 5 && (
                        <CommandInput placeholder="Search project..." />
                      )}
                      <CommandList className="max-h-56 overflow-y-auto">
                        <CommandEmpty>No projects found.</CommandEmpty>
                        <CommandGroup heading={'Projects'}>
                          {_.sortBy(projectsFiltered, ['name']).map(
                            (project, index) => {
                              const isChecked = project?.imaginations?.includes(
                                imagination.ref.path
                              );
                              return (
                                <CommandItem
                                  key={index}
                                  value={project.name}
                                  onSelect={() => {
                                    updateProject(
                                      project.ref,
                                      {
                                        imaginations: isChecked
                                          ? arrayRemove(imagination.ref.path)
                                          : arrayUnion(imagination.ref.path),
                                      },
                                      uid
                                    );
                                  }}
                                  className={'gap-2'}
                                >
                                  {project.name}
                                  {isChecked && (
                                    <CheckIcon
                                      className={cn('ml-auto h-4 w-4')}
                                    />
                                  )}
                                </CommandItem>
                              );
                            }
                          )}
                        </CommandGroup>
                      </CommandList>
                    </Command>
                  )}
                </DropdownMenuSubContent>
              </DropdownMenuPortal>
            </DropdownMenuSub>
          </DropdownMenuGroup>
          <DropdownMenuGroup>
            <DropdownMenuSub>
              <DropdownMenuSubTrigger>
                <BookmarkPlus className={'h-4 w-4 mr-2'} /> Add to Collections
              </DropdownMenuSubTrigger>
              <DropdownMenuPortal>
                <DropdownMenuSubContent>
                  {collections?.filter(collection => !collection.isArchive)
                    .length === 0 && (
                    <DropdownMenuItem
                      onClick={() => {
                        setCollectionOpen(true);
                      }}
                    >
                      Create New Collection
                    </DropdownMenuItem>
                  )}
                  {_.sortBy(collections, ['name'])
                    ?.filter(collection => !collection.isArchive)
                    ?.map(collection => {
                      const isChecked = collection?.imaginations?.includes(
                        imagination.ref.path
                      );
                      return (
                        <DropdownMenuCheckboxItem
                          key={collection.id}
                          checked={isChecked}
                          onCheckedChange={() => {
                            updateCollection(
                              collection.ref,
                              {
                                imaginations: isChecked
                                  ? arrayRemove(imagination.ref.path)
                                  : arrayUnion(imagination.ref.path),
                              },
                              uid
                            );
                          }}
                        >
                          {collection.name}
                        </DropdownMenuCheckboxItem>
                      );
                    })}
                </DropdownMenuSubContent>
              </DropdownMenuPortal>
            </DropdownMenuSub>
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>

      <AlertDialog>
        <AlertDialogTrigger asChild>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button variant="ghost" size="icon" className={'rounded-full'}>
                <Archive
                  className={'h-4 w-4'}
                  style={{ color: colors.eurykaRed }}
                />
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>Archive</p>
            </TooltipContent>
          </Tooltip>
        </AlertDialogTrigger>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Archive imagination</AlertDialogTitle>
            <AlertDialogDescription>
              {_.sample([
                'Archive Ahoy!',
                'Dreams on File!',
                'Memory Lane Awaits!',
                'Confirm to catapult creativity into the cosmos.',
              ])}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={onArchive}>Continue</AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};

ImaginationMenu.defaultProps = defaultProps;

export default ImaginationMenu;
