import { doc } from 'firebase/firestore';
import { useEffect, useMemo } from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';

import { useGlobalStore } from 'store';
import useUsersStore from 'store/users';
import { db } from 'utils/api/firebase';
import { superAdmins } from 'utils/config';
import { ROUTES } from 'utils/routes';

import useCommon from './useCommon';

const useUser = (props: { uid: string }) => {
  const { uid } = props;
  const { setInviteId, setInviteOpen } = useGlobalStore();
  const { router, wsId, setWsIdLocal } = useCommon();
  const { users, getUser } = useUsersStore();

  const [userDocData] = useDocumentData(doc(db, 'users', uid), {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  useEffect(() => {
    const user = users.find(u => u.id === uid);
    if (!user) return;
    for (const workspace of user.workspaces) {
      if (workspace?.status === 'pending') {
        setInviteId(workspace.id);
        setInviteOpen(true);
        break;
      }
    }
    if (!wsId) return;
    const workspaceIds = [uid, ...user.workspaces.map(ws => ws.id)];
    const isSuperAdmin = superAdmins.includes(uid);
    if (!isSuperAdmin && !workspaceIds.includes(wsId)) {
      setWsIdLocal(null);
      router.replace(ROUTES.WORKSPACE).then(() => {
        router.reload();
      });
    }
  }, [router, setInviteId, setInviteOpen, setWsIdLocal, uid, users, wsId]);

  useMemo(() => {
    getUser(uid, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUser, uid, userDocData]);

  return {};
};

export default useUser;
