import { AnimatePresence } from 'framer-motion';
import _ from 'lodash';
import { useSession } from 'next-auth/react';
import React, { useMemo } from 'react';
import { useLocalStorage } from 'usehooks-ts';

import Header from 'components/Header/Header';
import PageView from 'components/pageViews/PageView/PageView';
import { SidebarEntryProps } from 'components/Sidebar/SidebarEntry/SidebarEntry';
import * as Account from 'containers/AccountPage/AccountPage.utils';
import * as Dashboard from 'containers/DashboardPage/DashboardPage.utils';
import useCommon from 'hooks/useCommon';
import useUser from 'hooks/useUser';
import useWorkspace from 'hooks/useWorkspace';
import { useGlobalStore } from 'store';
import { superAdminsExtended } from 'utils/config';
import {
  isAccountPage,
  isDashboardPage,
  isFullWidthPage,
  isHeaderPage,
  isThreadPage,
  replaceDynamicSegments,
  ROUTES,
} from 'utils/routes';

import InviteDialog from './InviteDialog';
import LayoutCommandDialog from './LayoutCommandDialog';
import LayoutDescribeDialog from './LayoutDescribeDialog';
import LayoutGenerateDialog from './LayoutGenerateDialog';
import Intercom from './LayoutManager.intercom';
import LayoutSharedForms from './LayoutManager.sharedForms';
import LayoutNotifications from './LayoutNotifications';
import LayoutSidebar from './LayoutSidebar';
import LayoutWorkspacesDialog from './LayoutWorkspacesDialog';

import * as Styled from './LayoutManager.styles';

import { Toaster as ToasterSonnar } from '@/components/ui/sonner';
import { Toaster } from '@/components/ui/toaster';

export interface LayoutManagerProps {
  children: React.ReactNode | React.ReactNode[];
}

const defaultProps: Partial<LayoutManagerProps> = {};

const SyncCollections = ({ wsId }) => {
  const {} = useWorkspace({ wsId });
  return <></>;
};

const SyncUser = ({ uid }) => {
  const {} = useUser({ uid });
  return <></>;
};

const LayoutManager: React.FC<LayoutManagerProps> = ({ children }) => {
  const { router, wsId, wsIdLocal } = useCommon();
  const { data: session } = useSession();
  const uid = session?.user?.id;

  const { inviteId } = useGlobalStore();

  const activeStepId = useMemo(() => {
    for (const [key, value] of Object.entries(ROUTES)) {
      if (value === router.route) return _.camelCase(key);
    }
    return '';
  }, [router.route]);

  const activeParentId = useMemo(() => {
    if (activeStepId === 'characters') return 'scripts';
    if (activeStepId === 'voiceOvers') return 'scripts';
  }, [activeStepId]);

  const sidebarItems = useMemo(() => {
    let steps = [];
    if (isDashboardPage(router)) steps = Dashboard.sidebar.steps;
    if (isAccountPage(router)) steps = Account.sidebar.steps;

    return steps
      .filter(step => {
        if (step.superAdminExtended) {
          if (superAdminsExtended.includes(wsId)) {
            return true;
          } else {
            return false;
          }
        }
        return true;
      })
      .map(step => {
        const item: SidebarEntryProps = {
          ...step,
          isActive: step.id === activeStepId || step.id === activeParentId,
          ...(step.subItems && {
            subItems: step.subItems.map(subItem => {
              const subItemProps: SidebarEntryProps = {
                ...subItem,
                isActive: subItem.id === activeStepId,
              };
              return subItemProps;
            }),
          }),
        };
        return item;
      });
  }, [activeParentId, activeStepId, router, wsId]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isSidebarOverlayOpen, setSidebarOverlayOpen] = useLocalStorage(
    'isSidebarOverlayOpen',
    false
  );

  return (
    <>
      {(wsIdLocal || wsId) && <SyncCollections wsId={wsIdLocal || wsId} />}
      {uid && <SyncUser {...{ uid }} />}
      <Styled.Wrapper $isFullWidth={isFullWidthPage(router)}>
        {uid && isHeaderPage(router) && <Header {...{ uid }} />}
        <AnimatePresence mode="wait" initial={true}>
          <PageView
            {...((isDashboardPage(router) || isAccountPage(router)) && {
              sidebarProps: {
                items: sidebarItems,
                onChange: id => {
                  if (id === 'threads' && isThreadPage(router)) return;
                  router.push(
                    replaceDynamicSegments(
                      ROUTES[_.snakeCase(id).toUpperCase()],
                      router.query
                    )
                  );
                },
              },
            })}
            {...{ uid }}
          >
            {children}
          </PageView>
        </AnimatePresence>
        {(isDashboardPage(router) || isAccountPage(router)) && (
          <>
            <LayoutSidebar
              {...((isDashboardPage(router) || isAccountPage(router)) && {
                sidebarProps: {
                  items: sidebarItems,
                  onChange: id => {
                    if (id === 'threads' && isThreadPage(router)) return;
                    setSidebarOverlayOpen(false);
                    router.push(
                      replaceDynamicSegments(
                        ROUTES[_.snakeCase(id).toUpperCase()],
                        router.query
                      )
                    );
                  },
                },
              })}
              {...{ uid }}
            />
            <LayoutNotifications />
            <LayoutCommandDialog />
            <LayoutGenerateDialog />
            <LayoutDescribeDialog />
            {uid && <LayoutWorkspacesDialog {...{ uid }} />}
          </>
        )}
        {isDashboardPage(router) && <LayoutSharedForms />}
        {inviteId && <InviteDialog />}
        <Toaster />
        <ToasterSonnar position="bottom-center" offset={12} />
      </Styled.Wrapper>
      <Intercom />
    </>
  );
};

LayoutManager.defaultProps = defaultProps;

export default LayoutManager;
