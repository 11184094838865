import { Undo2 } from 'lucide-react';
import React, { useCallback } from 'react';

import useCommon from 'hooks/useCommon';
import { isAccountPage, ROUTES } from 'utils/routes';

import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';

export interface SidebarDashboardProps {}

const defaultProps: Partial<SidebarDashboardProps> = {};

const SidebarDashboard: React.FC<SidebarDashboardProps> = () => {
  const { router, routeTo } = useCommon();

  const onDashboardClick = useCallback(() => {
    routeTo(ROUTES.DASHBOARD_FALLBACK);
  }, [routeTo]);

  if (!isAccountPage(router)) return <div />;

  return (
    <div className={'mt-2 px-3'}>
      <Button
        variant="ghost"
        className={cn('w-full px-3 rounded-full justify-start h-10')}
        onClick={onDashboardClick}
      >
        <Undo2 className={'h-4 w-4 mr-2'} />
        <span className={'text-sm text-muted-foreground'}>Back</span>
      </Button>
    </div>
  );
};

SidebarDashboard.defaultProps = defaultProps;

export default SidebarDashboard;
