import * as Sentry from '@sentry/react';
import { useRouter } from 'next/router';
import { signOut as signOutNA } from 'next-auth/react';
import { useCallback } from 'react';
import { useLocalStorage } from 'usehooks-ts';

import { ROUTES } from 'utils/routes';

const useCommon = () => {
  const router = useRouter();
  const wsId = router.query.wsId as string;
  const [wsIdLocal, setWsIdLocal] = useLocalStorage('wsId', null);

  const onLogout = useCallback(async (route?: string) => {
    try {
      await signOutNA({
        callbackUrl: route || ROUTES.HOME,
      });
    } catch (error) {
      Sentry.captureException(error);
      if (process.env.IS_DEBUG)
        console.log('useCommon -- onLogout -- error:', error);
    }
  }, []);

  const routeTo = useCallback(
    async (route: string, uid?: string) => {
      router.push(route.replace('[wsId]', wsIdLocal || wsId || uid));
    },
    [router, wsId, wsIdLocal]
  );

  return {
    router,
    onLogout,
    wsId,
    wsIdLocal,
    setWsIdLocal,
    routeTo,
  };
};

export default useCommon;
