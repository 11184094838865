import _ from 'lodash';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

import useCommon from 'hooks/useCommon';
import { useGlobalStore } from 'store';
import useUsersStore from 'store/users';
import { ROUTES } from 'utils/routes';

import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';

export interface LayoutWorkspacesDialogProps {
  uid: string;
}

const defaultProps: Partial<LayoutWorkspacesDialogProps> = {};

const LayoutWorkspacesDialog: React.FC<LayoutWorkspacesDialogProps> = ({
  uid,
}) => {
  const router = useRouter();
  const { isWorkspacesOpen, setWorkspacesOpen } = useGlobalStore();
  const { wsId, setWsIdLocal } = useCommon();
  const { getUser } = useUsersStore();
  const [selectedWsId, setSelectedWsId] = useState(wsId);
  const [user, setUser] = useState(null);
  useEffect(() => {
    getUser(uid).then(setUser);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!user) return null;
  return (
    <Dialog open={isWorkspacesOpen} onOpenChange={setWorkspacesOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Switch workspace</DialogTitle>
          <DialogDescription>Select a workspace</DialogDescription>
        </DialogHeader>
        <Select
          onValueChange={value => {
            setSelectedWsId(value);
          }}
          defaultValue={wsId}
        >
          <SelectTrigger className="">
            <SelectValue placeholder="Select a workspace" />
          </SelectTrigger>
          <SelectContent>
            {!!user.workspaces?.length &&
              [...user.workspaces, { id: uid, role: 'Personal' }].map(
                (ws, index) => {
                  const { id, role } = ws;
                  return <WorkspaceSelectItem key={index} {...{ id, role }} />;
                }
              )}
          </SelectContent>
        </Select>
        <div>
          <Button
            className={'float-end'}
            onClick={async () => {
              setWsIdLocal(selectedWsId);
              setWorkspacesOpen(false);
              await router.replace(ROUTES.WORKSPACE);
              router.reload();
            }}
          >
            Continue
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

LayoutWorkspacesDialog.defaultProps = defaultProps;

export default LayoutWorkspacesDialog;

const WorkspaceSelectItem = (props: { id: string; role: string }) => {
  const { id, role } = props;
  const { users, getUser } = useUsersStore();
  const user = users.find(u => u.id === id);

  useEffect(() => {
    getUser(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SelectItem value={user?.id}>
      {`${user?.organizationName || user?.name || user?.displayName}`}{' '}
      <span className="text-muted-foreground">({_.capitalize(role)})</span>
    </SelectItem>
  );
};
