import _ from 'lodash';
import {
  Archive,
  Bolt,
  Component,
  LayoutGrid,
  Plus,
  Shapes,
  Users,
} from 'lucide-react';
import React from 'react';
import { useLocalStorage } from 'usehooks-ts';

import useProjects from 'containers/ProjectsPage/ProjectView.utils';
import useCommon from 'hooks/useCommon';
import { isProjectPage, isProjectsPage, ROUTES } from 'utils/routes';
import { colors } from 'utils/styles/theme';

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip';

const ProjectsMenu = (props: {
  uid: string;
  breadcrumbs?: boolean;
  actions?: boolean;
}) => {
  const { uid, breadcrumbs, actions } = props;
  const { router, wsId } = useCommon();
  const pId = router.query.pId as string;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [projectsView, setProjectsView] = useLocalStorage(
    'projectsView',
    'project'
  );

  if (!isProjectsPage(router) && !isProjectPage(router)) return <></>;
  return (
    <>
      {breadcrumbs && (
        <>
          <Separator orientation="vertical" className={'mx-1 h-5'} />
          <Breadcrumb>
            <BreadcrumbList>
              {(isProjectsPage(router) || isProjectPage(router)) && (
                <>
                  <BreadcrumbItem>
                    <span
                      className={'text-sm text-foreground cursor-pointer'}
                      onClick={() => {
                        router.push(ROUTES.PROJECTS.replace('[wsId]', wsId));
                      }}
                    >
                      Projects
                    </span>
                  </BreadcrumbItem>
                </>
              )}
              {pId && isProjectPage(router) && (
                <ProjectCrumb {...{ wsId, pId, uid }} />
              )}
            </BreadcrumbList>
          </Breadcrumb>
        </>
      )}
      {actions && (
        <div className={'flex items-center gap-1'}>
          {isProjectsPage(router) && (
            <>
              <Button
                type="button"
                onClick={() => {
                  router.push(
                    ROUTES.PROJECTS_CREATE.replace(
                      '[wsId]',
                      `${router.query.wsId}`
                    )
                  );
                }}
                size={'sm'}
              >
                <Plus className={'h-4 w-4 mr-2'} />
                New project
              </Button>
              <Separator orientation="vertical" className={'mx-1 h-5'} />
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant={projectsView === 'groups' ? 'secondary' : 'ghost'}
                    size="icon"
                    className={'rounded-full'}
                    onClick={() => setProjectsView('groups')}
                  >
                    <Component className={'h-4 w-4'} />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <p>Group view</p>
                </TooltipContent>
              </Tooltip>

              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant={projectsView === 'cards' ? 'secondary' : 'ghost'}
                    size="icon"
                    className={'rounded-full'}
                    onClick={() => setProjectsView('cards')}
                  >
                    <LayoutGrid className={'h-4 w-4'} />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <p>Grid view</p>
                </TooltipContent>
              </Tooltip>
            </>
          )}
          {pId && isProjectPage(router) && (
            <ProjectMenu {...{ wsId, pId, uid }} />
          )}
        </div>
      )}
    </>
  );
};
export default ProjectsMenu;

const ProjectCrumb = (props: { wsId; pId; uid }) => {
  const { wsId, pId, uid } = props;
  const { project } = useProjects({ wsId, uid, pId });
  return (
    <>
      <BreadcrumbSeparator />
      <BreadcrumbItem>
        <span className={'text-sm text-foreground'}>{project?.name}</span>
      </BreadcrumbItem>
    </>
  );
};

const ProjectMenu = (props: { wsId; pId; uid }) => {
  const { wsId, pId, uid } = props;
  const { onArchive } = useProjects({ wsId, uid, pId });

  const [projectView, setProjectView] = useLocalStorage(
    `projectView-${pId}`,
    'project'
  );

  return (
    <>
      <Button
        variant={projectView === 'project' ? 'secondary' : 'ghost'}
        size={'icon'}
        onClick={() => {
          setProjectView('project');
        }}
        className={'rounded-full'}
      >
        <Shapes className={'h-4 w-4'} />
      </Button>
      <Button
        variant={projectView === 'team' ? 'secondary' : 'ghost'}
        size={'icon'}
        onClick={() => {
          setProjectView('team');
        }}
        className={'rounded-full'}
      >
        <Users className={'h-4 w-4'} />
      </Button>
      <Button
        variant={projectView === 'settings' ? 'secondary' : 'ghost'}
        size={'icon'}
        onClick={() => {
          setProjectView('settings');
        }}
        className={'rounded-full'}
      >
        <Bolt className={'h-4 w-4'} />
      </Button>
      <Separator orientation="vertical" className={'mx-1 h-5'} />
      <AlertDialog>
        <AlertDialogTrigger asChild>
          <Button
            variant="ghost"
            size="icon"
            className={'rounded-full'}
            style={{ color: colors.eurykaRed }}
          >
            <Archive className={'h-4 w-4'} />
          </Button>
        </AlertDialogTrigger>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Archive project</AlertDialogTitle>
            <AlertDialogDescription>
              {_.sample([
                'Confirm to Crown Your Project Complete!',
                'Project Time Capsule Activated!',
                'Ready to wrap up this masterpiece?',
                'Prepare for Project Hibernation!',
              ])}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={onArchive}>Continue</AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};
