import { motion } from 'framer-motion';
import React from 'react';

export interface LogoWithTextrops {
  className?: string;
}

const defaultProps: Partial<LogoWithTextrops> = {};

const LogoWithText: React.FC<LogoWithTextrops> = ({ className }) => {
  return (
    <>
      <svg
        viewBox="0 0 157 47"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <g clipPath="url(#clip0_1_2)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15 31.5727C15 35.6715 11.6723 39 7.57317 39H0V31.4995C0 27.3612 3.36036 24 7.50004 24H13.5413C14.3463 24 15 24.6537 15 25.4586V31.5727ZM13.1211 28.1532V25.9777C12.6849 25.9777 12.3299 26.3328 12.3299 26.7689V28.9443C12.767 28.9443 13.1211 28.5903 13.1211 28.1532Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M31 38.5391L28.0561 40.6984C27.9246 40.795 27.8475 40.9499 27.8475 41.1139V41.6482C27.8475 41.7196 27.8752 41.7891 27.9256 41.8394C27.9751 41.8907 28.0433 41.9189 28.1135 41.9189H31V47H23.4273C19.3285 47 16 43.6142 16 39.4427V25.4972C16 24.6711 16.6596 24 17.4714 24H23.5005C27.6388 24 31 27.42 31 31.6308V38.5391ZM17.8788 30.2383C17.8788 30.683 18.2338 31.0432 18.6699 31.0432V26.8173C18.6699 26.3736 18.3159 26.0124 17.8788 26.0124V30.2383Z"
            fill="#FF001C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 19.1777C16 14.6641 19.6648 11 24.178 11H28V14.6C28 19.2357 24.236 23 19.5997 23H17.1978C16.5368 23 16 22.4631 16 21.803V19.1777ZM17.903 18.793V20.9966C18.3457 20.9966 18.7043 20.638 18.7043 20.1953V17.9916C18.2626 17.9916 17.903 18.3502 17.903 18.793Z"
            fill="currentColor"
          />
          <motion.path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M31.5 2C31.5 5.03595 33.9644 7.5 37 7.5C33.9644 7.5 31.5 9.96398 31.5 13C31.5 9.96398 29.0355 7.5 26 7.5C29.0355 7.5 31.5 5.03595 31.5 2Z"
            fill="currentColor"
            initial={{ scale: 1, rotate: 0 }}
            animate={{ scale: [0.875, 1.0625], rotate: [-3, 3] }}
            transition={{
              duration: 0.75,
              ease: 'easeInOut',
              repeat: Infinity,
              repeatType: 'mirror',
              delay: 0.75 / 2.5,
              times: [0, 1],
            }}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 13.0621L1.77908 11.251L0 9.44088L1.77908 7.6308L0 5.82072L1.77908 4.01057L0 2.20049V0H7.57317C11.6723 0 15 3.38681 15 7.55734V21.4515C15 22.3058 14.3177 23 13.478 23H7.50004C3.36036 23 0 19.581 0 15.3692V13.0621ZM13.1211 16.7617C13.1211 16.3171 12.767 15.9569 12.3299 15.9569V20.1828C12.3299 20.6274 12.6849 20.9876 13.1211 20.9876V16.7617Z"
            fill="#FF001C"
          />
          <motion.path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M36.5 10C36.5 11.3799 37.6202 12.5 39 12.5C37.6202 12.5 36.5 13.62 36.5 15C36.5 13.62 35.3798 12.5 34 12.5C35.3798 12.5 36.5 11.3799 36.5 10Z"
            fill="currentColor"
            initial={{ scale: 1, rotate: 0 }}
            animate={{ scale: [0.875, 1.1875], rotate: [-3, 3] }}
            transition={{
              duration: 0.75,
              ease: 'easeInOut',
              repeat: Infinity,
              repeatType: 'mirror',
              delay: 0.75 / 7.5,
              times: [0, 1],
            }}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M64 25.9C64 19.075 59.1838 16 54.2574 16C47.75 16 44 20.35 44 26.5C44 32.35 47.6029 37 54.3676 37C59.8456 37 62.8603 33.55 63.6324 29.8H57.7868C57.3088 31.2625 56.1691 32.2 54.3309 32.2C51.8676 32.2 50.2132 30.5125 49.8088 28H63.8897C63.9632 27.4375 64 26.4625 64 25.9ZM54.3676 20.65C56.5368 20.65 58.0074 22 58.2279 24.4H49.9559C50.3971 22.2625 51.5735 20.65 54.3676 20.65Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M73.197 37C76.6439 37 78.8409 34.3529 79.8258 32.4412C79.9773 32.1471 80.1667 31.7059 80.6212 31.8897C81.1136 32.0735 80.7349 32.8088 80.4318 33.3971C80.1288 33.9853 79.9394 34.6103 79.9394 35.4926V36.4118H86V17H79.9394V24.4632C79.9394 28.9485 77.7045 32 75.2424 32C73.0833 32 72.0606 30.7868 72.0606 26.4118V17H66V28.7647C66 34.2794 68.8788 37 73.197 37Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M94.429 21.5149C93.875 21.403 94.0966 20.7313 94.392 20.1343C94.6875 19.5373 94.9091 18.8657 94.9091 18.194V17.2985H89V37H94.9091V29.3507C94.9091 25.3225 96.0355 23.256 99.2742 22.8C100.838 22.5697 101.997 21.2148 101.997 19.6181C102 18.2714 102 17 102 17C97.3097 17 96.2017 18.3806 95.0568 20.9925C94.946 21.2537 94.7614 21.5896 94.429 21.5149Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M102.779 22.117C100.144 23.5134 98.967 26.6395 100.032 29.4114C101.393 32.9531 103 37.1375 103 37.1375L102.774 37.7C102.094 39.35 101.528 39.2 98.6604 39.2H97V44H101.83C105.302 44 107.189 42.6125 108.547 39.0875L117 17H110.585L107.151 28.7375C106.925 29.4875 106.887 30.1625 106.811 30.8375C106.774 31.2125 106.736 31.625 106.208 31.625C105.679 31.625 105.604 31.2125 105.604 30.8375C105.604 30.1625 105.528 29.4875 105.302 28.7375L103.191 21.8983C103.191 21.8983 103.035 21.9809 102.779 22.117Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M138 37L129.324 25.4875L137.412 17.2H130.353L123.735 24.5125C123.478 24.8125 123.184 25.0375 122.853 24.775C122.485 24.5125 122.706 24.1 122.963 23.8C123.515 23.1625 123.882 22.4875 123.882 21.0625V10H118V37H123.882V31.0375L125.5 29.3875L130.941 37H138Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M137 31.1125C137 34.4125 139.917 37 143.97 37C147.303 37 149.538 35.2375 150.788 32.8C150.939 32.5375 151.091 32.0875 151.583 32.3125C152 32.5 151.659 33.1 151.356 33.7375C151.091 34.2625 150.939 34.7875 150.939 35.3875V36.4H157V25.45C157 17.5 152.417 16 147.682 16C143.212 16 138.288 17.9125 137.606 23.2H143.591C143.894 21.7 144.803 20.5 147.53 20.5C150.295 20.5 150.902 21.925 150.939 23.05C150.977 24.7375 148.439 24.5125 144.879 24.7375C140.561 25 137 26.65 137 31.1125ZM150.939 26.8C150.902 30.8875 148.098 32.5 146.28 32.8C144.652 33.0625 143.061 32.35 143.061 30.7C143.061 29.0125 144.311 28.375 145.712 28.225C148.553 27.8875 149.955 27.8125 150.939 26.8Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_1_2">
            <rect width="157" height="47" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

LogoWithText.defaultProps = defaultProps;

export default LogoWithText;
