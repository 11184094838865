import { GanttChartSquare, LayoutGrid, Menu } from 'lucide-react';
import React, { useCallback } from 'react';
import { useLocalStorage } from 'usehooks-ts';

import LogoWithText from 'components/LogoWithText/LogoWithText';
import useCommon from 'hooks/useCommon';
import { useGlobalStore } from 'store';
import {
  isAccountPage,
  isCharactersPage,
  isCollectionPage,
  isCollectionsPage,
  isImaginationsPage,
  isImagitnationPage,
  isThreadPage,
  isVoiceoversPage,
  ROUTES,
} from 'utils/routes';

import AccountMenu from './Header.account';
import BrandsMenu from './Header.brands';
import CharactersMenu from './Header.characters';
import CollectionsMenu from './Header.collections';
import ImaginationMenu from './Header.imaginationMenu';
import PlaygroundsMenu from './Header.playground';
import ProjectsMenu from './Header.projects';
import ThreadsMenu from './Header.threads';
import VoiceoversMenu from './Header.voiceovers';

import * as Styled from './Header.styles';

import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { useSvgIcon } from 'growl/hooks';

export interface HeaderProps {
  uid: string;
}

const defaultProps: Partial<HeaderProps> = {};

const Header: React.FC<HeaderProps> = ({ uid }) => {
  const { router, routeTo } = useCommon();

  const { setCommandOpen } = useGlobalStore();

  const onHomeClick = useCallback(() => {
    isAccountPage(router)
      ? router.push(ROUTES.WORKSPACE)
      : router.push(ROUTES.HOME);
  }, [router]);

  const [imaginationsViewIsLiked, setImaginationsViewLiked] = useLocalStorage(
    'isLiked',
    false
  );

  const HeartIcon = useSvgIcon(
    imaginationsViewIsLiked ? 'heart-filled' : 'heart'
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [imaginationsView, setImaginationsView] = useLocalStorage(
    'imaginationsView',
    'grid'
  );

  const [isSidebarOpen, setSidebarOpen] = useLocalStorage(
    'isSidebarOpen',
    true
  );
  const [isSidebarOverlayOpen, setSidebarOverlayOpen] = useLocalStorage(
    'isSidebarOverlayOpen',
    false
  );

  return (
    <>
      <div
        className={
          'sticky top-0 z-10 w-full border-b border-border/40 bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60 flex justify-between items-center h-14 px-5'
        }
      >
        <div className={'flex items-center gap-2'}>
          <Button
            variant="ghost"
            size="icon"
            className={'rounded-full hidden lg:flex'}
            onClick={() => {
              setSidebarOpen(!isSidebarOpen);
            }}
          >
            <Menu className={'h-5 w-5'} />
          </Button>
          <Button
            variant="ghost"
            size="icon"
            className={'rounded-full lg:hidden'}
            onClick={() => {
              setSidebarOverlayOpen(!isSidebarOverlayOpen);
            }}
          >
            <Menu className={'h-5 w-5'} />
          </Button>
          <Styled.LogoWrapper onClick={onHomeClick}>
            <LogoWithText />
          </Styled.LogoWrapper>

          {isThreadPage(router) && (
            <>
              <Separator orientation="vertical" className={'mx-1 h-5'} />
              <span className={'text-sm text-foreground'}>Threads</span>
            </>
          )}
          {(isImaginationsPage(router) || isImagitnationPage(router)) && (
            <>
              <Separator orientation="vertical" className={'mx-1 h-5'} />
              <span
                className={'text-sm text-foreground cursor-pointer'}
                onClick={() => routeTo(ROUTES.IMAGINATIONS)}
              >
                Imaginations
              </span>
            </>
          )}
          {isCharactersPage(router) && (
            <>
              <Separator orientation="vertical" className={'mx-1 h-5'} />
              <span className={'text-sm text-foreground'}>Characters</span>
            </>
          )}
          {isVoiceoversPage(router) && (
            <>
              <Separator orientation="vertical" className={'mx-1 h-5'} />
              <span className={'text-sm text-foreground'}>Voiceovers</span>
            </>
          )}
          <BrandsMenu {...{ uid }} breadcrumbs />
          <ProjectsMenu {...{ uid }} breadcrumbs />
          <CollectionsMenu breadcrumbs />
          {isAccountPage(router) && (
            <>
              <Separator orientation="vertical" className={'mx-1 h-5'} />
              <span className={'text-sm text-foreground'}>Account</span>
            </>
          )}
          <PlaygroundsMenu breadcrumbs />
        </div>
        <div className="w-full max-w-96">
          <Button
            variant={'outline'}
            className={'w-full flex justify-between font-normal pr-2'}
            onClick={() => setCommandOpen(true)}
          >
            <span className={'text-muted-foreground'}>
              Describe your thoughts...{' '}
            </span>
            <kbd className="pointer-events-none inline-flex h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium text-muted-foreground opacity-100">
              <span className="text-xs">⌘</span>E
            </kbd>
          </Button>
        </div>

        <div className={'flex items-center'}>
          {isImaginationsPage(router) && (
            <>
              <div className={'space-x-1'}>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      variant={imaginationsViewIsLiked ? 'secondary' : 'ghost'}
                      size="icon"
                      className={'rounded-full'}
                      onClick={() =>
                        setImaginationsViewLiked(!imaginationsViewIsLiked)
                      }
                    >
                      <HeartIcon.SvgIcon className={'h-4 w-4'} />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>Favourites</p>
                  </TooltipContent>
                </Tooltip>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      variant={
                        imaginationsView === 'grid' ? 'secondary' : 'ghost'
                      }
                      size="icon"
                      className={'rounded-full'}
                      onClick={() => setImaginationsView('grid')}
                    >
                      <LayoutGrid className={'h-4 w-4'} />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>Grid view</p>
                  </TooltipContent>
                </Tooltip>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      variant={
                        imaginationsView === 'timeline' ? 'secondary' : 'ghost'
                      }
                      size="icon"
                      className={'rounded-full'}
                      onClick={() => setImaginationsView('timeline')}
                    >
                      <GanttChartSquare className={'h-4 w-4'} />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>Timeline view</p>
                  </TooltipContent>
                </Tooltip>
              </div>
            </>
          )}
          <ImaginationMenu {...{ uid }} />
          <ThreadsMenu />
          <CharactersMenu />
          <VoiceoversMenu />
          <BrandsMenu {...{ uid }} actions />
          <ProjectsMenu {...{ uid }} actions />
          {(isCollectionsPage(router) || isCollectionPage(router)) && (
            <CollectionsMenu actions />
          )}
          <AccountMenu />
          <PlaygroundsMenu actions />
        </div>
      </div>
    </>
  );
};

Header.defaultProps = defaultProps;

export default Header;
