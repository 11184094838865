/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from 'firebase/firestore';
import _ from 'lodash';
import { useSession } from 'next-auth/react';
import { useCallback } from 'react';

import useUsersStore from 'store/users';
import API from 'utils/api';
import { db } from 'utils/api/firebase';

import useCommon from './useCommon';

import { useToast } from '@/components/ui/use-toast';

const useAccount = () => {
  const { router } = useCommon();
  const { data } = useSession();
  const uid = data?.user?.id;

  const { getUser } = useUsersStore();

  const { toast } = useToast();

  const onUpdateOrg = useCallback(
    async (props: { wsId: string; name: string }) => {
      try {
        const { wsId, name } = props;
        await updateDoc(doc(db, 'users', wsId), {
          organization: {
            name,
          },
          updatedAt: serverTimestamp(),
        });
      } catch (error) {
        console.log('useAccount -- onUpdateOrg -- error:', error);
        toast({
          title: 'Something went wrong. Please try again.',
        });
      }
    },
    [toast]
  );

  const onUpdateProfile = useCallback(
    async data => {
      try {
        await updateDoc(doc(db, 'users', uid), {
          'user.name': data.name,
          updatedAt: serverTimestamp(),
        });
      } catch (error) {
        console.log('useAccount -- onUpdateProfile -- error:', error);
        toast({
          title: 'Something went wrong. Please try again.',
        });
      }
    },
    [toast, uid]
  );

  const addMember = useCallback(
    async (props: { wsId: string; email: string; role: string }) => {
      const { wsId, email, role } = props;
      if (data?.user?.email === email) return;
      const wsDocRef = doc(db, 'users', wsId);
      const wsDocSnap = await getDoc(wsDocRef);
      if (!wsDocSnap.exists()) return;

      const { user, organization, memberIds } = wsDocSnap.data();
      if (memberIds.includes(email)) return;

      const wsName =
        organization?.name || user?.name || user?.displayName || '';

      updateDoc(wsDocRef, {
        memberIds: arrayUnion(email),
        members: arrayUnion({
          email,
          status: 'pending',
          role: role,
        }),
        updatedAt: serverTimestamp(),
      });

      const usersDocsSnap = await getDocs(
        query(collection(db, 'users'), where('user.email', '==', email))
      );
      if (!usersDocsSnap.empty) {
        const userDocSnap = _.first(usersDocsSnap.docs);
        const { user, workspaceIds } = userDocSnap.data();
        if (!workspaceIds.includes(wsId)) {
          updateDoc(userDocSnap.ref, {
            workspaces: arrayUnion({
              id: wsId,
              role: role,
              status: 'pending',
            }),
            workspaceIds: arrayUnion(wsId),
            updatedAt: serverTimestamp(),
          });

          const name = user?.name || user?.displayName || '';

          API.resendInviteToWs({
            to: email,
            toDisplayName: name,
            fromDisplayName: wsName,
          });
        }

        return;
      }

      API.resendInviteToWs({
        to: email,
        toDisplayName: '',
        fromDisplayName: wsName,
      });
    },
    [data?.user?.email]
  );

  const onAddMembers = useCallback(
    async (props: { wsId: string; emails: string[]; role: string }) => {
      try {
        const { wsId, emails, role } = props;
        for (const email of emails) {
          addMember({ wsId, email, role });
        }
      } catch (error) {
        console.log('useAccount -- onUpdateProfile -- error:', error);
        toast({
          title: 'Something went wrong. Please try again.',
        });
      }
    },
    [addMember, toast]
  );

  const onUpdateMember = useCallback(
    async (props: { wsId: string; email: string; role: string }) => {
      try {
        const { wsId, email, role } = props;
        const wsDocRef = doc(db, 'users', wsId);
        const wsDocSnap = await getDoc(wsDocRef);
        if (!wsDocSnap.exists()) return;

        const { members = [] } = wsDocSnap.data();
        const member = members.find(member => member.email === email);
        if (member) {
          await updateDoc(wsDocRef, {
            members: arrayRemove(member),
            updatedAt: serverTimestamp(),
          });
          await updateDoc(wsDocRef, {
            members: arrayUnion({ ...member, role }),
            updatedAt: serverTimestamp(),
          });
        }

        const usersDocsSnap = await getDocs(
          query(collection(db, 'users'), where('user.email', '==', email))
        );
        if (!usersDocsSnap.empty) {
          const userDocSnap = _.first(usersDocsSnap.docs);
          const { workspaces = [] } = userDocSnap.data();
          const ws = workspaces.find(ws => ws.id === wsId);
          if (ws) {
            await updateDoc(userDocSnap.ref, {
              workspaces: arrayRemove(ws),
              updatedAt: serverTimestamp(),
            });
            await updateDoc(userDocSnap.ref, {
              workspaces: arrayUnion({ ...ws, role }),
              updatedAt: serverTimestamp(),
            });
          }
        }
      } catch (error) {
        console.log('useAccount -- onUpdateMember -- error:', error);
        toast({
          title: 'Something went wrong. Please try again.',
        });
      }
    },
    [toast]
  );

  const onDeleteMember = useCallback(
    async (props: { wsId: string; email: string }) => {
      try {
        const { wsId, email } = props;
        const wsDocRef = doc(db, 'users', wsId);
        const wsDocSnap = await getDoc(wsDocRef);
        if (!wsDocSnap.exists()) return;

        const { members = [] } = wsDocSnap.data();
        const member = members.find(member => member.email === email);
        if (member) {
          await updateDoc(wsDocRef, {
            members: arrayRemove(member),
            memberIds: arrayRemove(member.email),
            updatedAt: serverTimestamp(),
          });
        }

        const usersDocsSnap = await getDocs(
          query(collection(db, 'users'), where('user.email', '==', email))
        );
        if (!usersDocsSnap.empty) {
          const userDocSnap = _.first(usersDocsSnap.docs);
          const { workspaces = [] } = userDocSnap.data();
          const ws = workspaces.find(ws => ws.id === wsId);
          if (ws) {
            await updateDoc(userDocSnap.ref, {
              workspaces: arrayRemove(ws),
              workspaceIds: arrayRemove(wsId),
              updatedAt: serverTimestamp(),
            });
          }
        }
      } catch (error) {
        console.log('useAccount -- onUpdateProfile -- error:', error);
        toast({
          title: 'Something went wrong. Please try again.',
        });
      }
    },
    [toast]
  );

  const onUpdateMemberStatus = useCallback(
    async (inviteId, status: 'accepted' | 'declined') => {
      try {
        const { email, workspaces } = await getUser(uid);
        const ws = workspaces.find(workspace => workspace.id === inviteId);
        if (ws) {
          await updateDoc(doc(db, 'users', uid), {
            workspaces: arrayRemove(ws),
            updatedAt: serverTimestamp(),
          });
          await updateDoc(doc(db, 'users', uid), {
            workspaces: arrayUnion({ ...ws, status }),
            updatedAt: serverTimestamp(),
          });
        }

        const { members } = await getUser(inviteId);
        const member = members.find(member => member.email === email);
        if (member) {
          await updateDoc(doc(db, 'users', inviteId), {
            members: arrayRemove(member),
            updatedAt: serverTimestamp(),
          });
          await updateDoc(doc(db, 'users', inviteId), {
            members: arrayUnion({ ...member, status }),
            updatedAt: serverTimestamp(),
          });
        }
      } catch (error) {
        console.log('useAccount -- onUpdateMemberStatus -- error:', error);
        toast({
          title: 'Something went wrong. Please try again.',
        });
      }
    },
    [getUser, toast, uid]
  );

  return {
    onUpdateOrg,
    onUpdateProfile,
    onAddMembers,
    onUpdateMember,
    onDeleteMember,
    onUpdateMemberStatus,
  };
};

export default useAccount;
