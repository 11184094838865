export const sidebar = {
  steps: [
    // {
    //   id: 'welcome',
    //   label: 'Welcome',
    // },
    {
      id: 'dashboard',
      icon: 'layout-dashboard',
      label: 'Dashboard',
    },
    {
      id: 'imaginations',
      icon: 'sparkles',
      label: 'Imaginations',
    },
    {
      id: 'threads',
      icon: 'message-square-quote',
      label: 'Threads',
    },
    // {
    //   id: 'divider',
    //   label: 'Divider',
    // },
    // {
    //   id: 'scripts',
    //   label: 'Scripts',
    //   subItems: [
    //     {
    //       id: 'characters',
    //       label: 'Characters',
    //       parentId: 'scripts',
    //     },
    //     {
    //       id: 'voiceovers',
    //       label: 'Voice overs',
    //       parentId: 'scripts',
    //     },
    //   ],
    // },
    {
      id: 'characters',
      icon: 'users',
      label: 'Characters',
      parentId: 'scripts',
    },
    {
      id: 'voiceovers',
      icon: 'audio-waveform',
      label: 'Voiceovers',
      parentId: 'scripts',
    },
    {
      id: 'divider',
      label: 'Divider',
    },
    {
      id: 'brands',
      icon: 'pyramid',
      label: 'Brands',
    },
    {
      id: 'projects',
      icon: 'library-big',
      label: 'Projects',
    },
    {
      id: 'collections',
      icon: 'bookmark',
      label: 'Collections',
    },
    {
      id: 'divider',
      label: 'Divider',
      superAdminExtended: true,
    },
    {
      id: 'playground',
      icon: 'pencil-ruler',
      label: 'Playground',
      superAdminExtended: true,
    },
  ],
};
