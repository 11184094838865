import { MoonIcon, SunIcon } from '@radix-ui/react-icons';
import { Bell, Bolt, Info, LogOut, SwatchBook } from 'lucide-react';
import { useTheme } from 'next-themes';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { WsAvatar } from 'components/WsAvatar';
import useCommon from 'hooks/useCommon';
import { useGlobalStore } from 'store';
import useUsersStore from 'store/users';
import { ROUTES } from 'utils/routes';

import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Separator } from '@/components/ui/separator';
import { cn } from '@/lib/utils';

export interface SidebarAccountProps {
  uid?: string;
}

const defaultProps: Partial<SidebarAccountProps> = {};

const SidebarAccount: React.FC<SidebarAccountProps> = ({ uid }) => {
  const { setNotificationsOpen, setWorkspacesOpen } = useGlobalStore();
  const { users, getUser } = useUsersStore();
  const { router, routeTo, wsId, onLogout } = useCommon();
  const { theme, setTheme } = useTheme();

  const onAccountClick = useCallback(() => {
    routeTo(ROUTES.ACCOUNT_ORG, uid);
  }, [routeTo, uid]);

  useEffect(() => {
    getUser(uid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const user = useMemo(() => {
    return users.find(user => user.id === uid);
  }, [uid, users]);

  if (!user) return <></>;
  return (
    <div className={'sticky bottom-0 px-3 pt-3'}>
      <Button
        variant="ghost"
        className={cn(
          'w-full px-3 rounded-full justify-start h-10',
          user.workspaces.length ? 'visible' : 'hidden'
        )}
        onClick={() => {
          setWorkspacesOpen(true);
        }}
      >
        <SwatchBook className={'h-4 w-4 mr-2'} />
        <Ws wsId={wsId || uid} isPersonal={router.query.wsId === uid} />
      </Button>
      <Separator
        className={cn('my-3', user.workspaces.length ? 'visible' : 'hidden')}
      />
      <Button
        variant="ghost"
        className={cn('w-full px-3 rounded-full justify-start h-10')}
        onClick={() => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          window?.Intercom?.('showSpace', 'help');
        }}
      >
        <Info className={'h-4 w-4 mr-2'} />
        <span className={'text-sm font-normal text-muted-foreground'}>
          Help
        </span>
      </Button>
      <Button
        variant="ghost"
        className={cn('w-full px-3 rounded-full justify-start h-10')}
        onClick={() => setNotificationsOpen(true)}
      >
        <Bell className={'h-4 w-4 mr-2'} />
        <span className={'text-sm font-normal text-muted-foreground'}>
          Notifications
        </span>
      </Button>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="ghost"
            className={cn('w-full px-3 rounded-full justify-start h-10')}
          >
            {theme === 'light' && <SunIcon className="h-4 w-4 mr-2" />}
            {theme === 'dark' && <MoonIcon className="h-4 w-4 mr-2" />}
            <span className={'text-sm font-normal text-muted-foreground'}>
              Toggle theme
            </span>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent side={'top'} align={'start'}>
          <DropdownMenuItem onClick={() => setTheme('light')}>
            Light
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => setTheme('dark')}>
            Dark
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => setTheme('system')}>
            System
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      <Button
        variant="ghost"
        className={cn('w-full px-3 rounded-full justify-start h-10')}
        onClick={onAccountClick}
      >
        <Bolt className={'h-4 w-4 mr-2'} />
        <span className={'text-sm font-normal text-muted-foreground'}>
          Account
        </span>
      </Button>
      <div className={'my-3 flex justify-between items-center gap-1'}>
        <div className={'flex items-center gap-1'}>
          <WsAvatar
            {...{
              photoURL: user?.photoURL,
              name: user?.name,
            }}
          />
          <span
            className={
              'text-sm text-muted-foreground mr-2 line-clamp-1 break-all whitespace-normal'
            }
          >
            {user.name || user.displayName}
          </span>
        </div>
        <div className={'flex items-center'}>
          <Separator orientation="vertical" className={'h-5 mr-1'} />
          <Button
            variant="ghost"
            size="icon"
            className={'rounded-full mr-auto'}
            onClick={() => onLogout()}
          >
            <LogOut className={'h-4 w-4'} />
          </Button>
        </div>
      </div>
    </div>
  );
};

SidebarAccount.defaultProps = defaultProps;

export default SidebarAccount;

const Ws = (props: { wsId: string; isPersonal: boolean }) => {
  const { wsId, isPersonal } = props;
  const { getUser } = useUsersStore();
  const [user, setUser] = useState(null);
  useEffect(() => {
    getUser(wsId).then(u => setUser(u));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const name = user?.organizationName || user?.name || user?.displayName;
  return (
    <span
      className={
        'text-sm text-muted-foreground line-clamp-1 break-all whitespace-normal'
      }
    >{`${name || 'Workspaces'}${isPersonal ? ' (Personal)' : ''}`}</span>
  );
};
