export const ISR_TIMEOUT = process.env.ENV === 'production' ? 300 : 10;

export const POINT_IMG_PREFIX =
  'https://tssghcursuwjloiwtlwl.supabase.co/storage/v1/object/public/files/';

export const TICK_LENGTH = 10;

export const MARGIN = { top: 60, right: 60, bottom: 60, left: 60 };

export const superAdmins = [
  // Sabi
  '7rRig6xHQEUBR1J9feWnqc9JvxU2',
  'kmSVowKcfPgT9vVpABoE7Lhj3fE2',
];

export const superAdminsExtended = [
  ...superAdmins,
  // Dinesh
  // prod
  'vUYkHrFImXdbFrddC7eC0JmlZLW2',
  'ujrcWNggVsTMbSKzXaQ6O3Vz7DA2',
  'zNpQY9SyvvcfMXlDvbOpfYqoSZo2',
  // preview
  'OQfgVlIT73Y72G1I9t8wQXGYXhw1',
  'hUPggsc5HKMpFH9TXqaUeLnqFYp1',
  '5uuPXSZl5ZbaiARhT3n8AgN60AL2',
  'diV2OKqpoFbi6vHeefR5ABzh0193',
];

export const delay = (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

export const SUNO_API_URL = 'https://suno-api-yyrizyhnma-ez.a.run.app/';
