import { useRouter } from 'next/router';
import React from 'react';

import { isAccountPage } from 'utils/routes';

const AccountMenu = () => {
  const router = useRouter();

  if (!isAccountPage(router)) return <></>;
  return <div className={'flex items-center gap-1'}></div>;
};
export default AccountMenu;
