/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AudioWaveform,
  BarChart2,
  Bookmark,
  Building,
  CircleUser,
  LayoutDashboard,
  LibraryBig,
  MessageSquareQuote,
  PencilRuler,
  Pyramid,
  Receipt,
  Sparkles,
  Star,
  Users,
} from 'lucide-react';
import React from 'react';

import { TODO } from 'store/global.types';

import * as Styled from './SidebarEntry.styles';

import { cn } from '@/lib/utils';

export interface SidebarEntryProps {
  id: string;
  label: string;
  icon?: string;
  isActive?: boolean;
  hasSubItems?: boolean;
  parentId?: string;
  onClick?: (id: string, parentId?: string) => void;
  subItems?: SidebarEntryProps[];
  isSubItem?: boolean;
  disabled?: boolean;
  edit?: React.ReactNode;
  isStarred?: boolean;
}

const defaultProps: Partial<SidebarEntryProps> = {
  isActive: false,
  hasSubItems: false,
  onClick: () => null,
  subItems: [],
  disabled: false,
  isStarred: false,
};

const IconComponent = (props: { icon: TODO; className: string }) => {
  const { icon: Icon, className } = props;
  return <Icon className={className} />;
};

const SidebarEntry: React.FC<SidebarEntryProps> = ({
  id,
  label,
  icon,
  isActive,
  hasSubItems,
  onClick,
  subItems,
  isSubItem,
  disabled,
  edit,
  isStarred,
}) => {
  return (
    <div>
      <Styled.Inner
        className={cn(
          'relative h-9 px-3 rounded-full flex items-center cursor-pointer hover:bg-muted',
          isSubItem ? 'ml-5' : 'ml-0',
          isActive ? 'bg-muted' : 'bg-transparent'
        )}
        onClick={() => onClick(id)}
      >
        {isStarred && (
          <Star
            className={
              'absolute h-4 w-4 text-foreground flex-shrink-0 isStarred fill-foreground'
            }
          />
        )}

        {icon === 'layout-dashboard' && <LayoutDashboard className="h-4 w-4" />}
        {icon === 'sparkles' && <Sparkles className="h-4 w-4" />}
        {icon === 'message-square-quote' && (
          <MessageSquareQuote className="h-4 w-4" />
        )}
        {icon === 'users' && <Users className="h-4 w-4" />}
        {icon === 'audio-waveform' && <AudioWaveform className="h-4 w-4" />}
        {icon === 'pyramid' && <Pyramid className="h-4 w-4" />}
        {icon === 'library-big' && <LibraryBig className="h-4 w-4" />}
        {icon === 'bookmark' && <Bookmark className="h-4 w-4" />}
        {icon === 'building' && <Building className="h-4 w-4" />}
        {icon === 'circle-user' && <CircleUser className="h-4 w-4" />}
        {icon === 'receipt' && <Receipt className="h-4 w-4" />}
        {icon === 'bar-chart-2' && <BarChart2 className="h-4 w-4" />}
        {icon === 'pencil-ruler' && <PencilRuler className="h-4 w-4" />}

        <span
          className={cn(
            'text-sm line-clamp-1',
            isStarred ? 'ml-5' : 'ml-0',
            isActive ? 'text-foreground' : 'text-muted-foreground'
          )}
        >
          {label}
        </span>
        {edit && <>{edit}</>}
      </Styled.Inner>
      {hasSubItems && (
        <ul style={{ all: 'unset' }}>
          {subItems.map((entry, index) => {
            return (
              <Styled.Li key={index}>
                <SidebarEntry
                  {...entry}
                  onClick={() => onClick(entry.id, entry.parentId)}
                  isSubItem
                />
              </Styled.Li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

SidebarEntry.defaultProps = defaultProps;

export default SidebarEntry;
