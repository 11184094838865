import { Plus } from 'lucide-react';
import { useRouter } from 'next/router';
import React from 'react';

import { useGlobalStore } from 'store';
import { isVoiceoversPage } from 'utils/routes';

import { Button } from '@/components/ui/button';

const VoiceoversMenu = () => {
  const router = useRouter();
  const { setVoiceoverOpen } = useGlobalStore();

  if (!isVoiceoversPage(router)) return <></>;
  return (
    <div className={'flex items-center gap-1'}>
      <Button type="button" onClick={() => setVoiceoverOpen(true)} size={'sm'}>
        <Plus className={'h-4 w-4 mr-2'} />
        New Voiceover
      </Button>
    </div>
  );
};
export default VoiceoversMenu;
