import _ from 'lodash';
import { Archive, Bolt, Plus } from 'lucide-react';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useState } from 'react';

import { useGlobalStore } from 'store';
import useCollectionsStore from 'store/collections';
import { isCollectionPage, isCollectionsPage, ROUTES } from 'utils/routes';
import { colors } from 'utils/styles/theme';

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';
import { cn } from '@/lib/utils';

const CollectionsMenu = (props: {
  breadcrumbs?: boolean;
  actions?: boolean;
}) => {
  const router = useRouter();
  const wsId = router.query.wsId as string;
  const cId = router.query.cId as string;
  const { actions, breadcrumbs } = props;
  const { setCollectionOpen, setCollectionRef } = useGlobalStore();
  const { collections, getCollection, updateCollection } =
    useCollectionsStore();
  const [collection, setCollection] = useState(null);

  const fetchCollection = useCallback(async () => {
    if (!cId) return;
    try {
      const c = await getCollection(wsId, cId);
      setCollection(c);
    } catch (error) {
      //
    }
  }, [cId, getCollection, wsId]);

  useEffect(() => {
    fetchCollection();
  }, [collections, fetchCollection]);

  useEffect(() => {
    fetchCollection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isCollectionsPage(router) && !isCollectionPage(router)) return <></>;
  return (
    <>
      {breadcrumbs && (
        <>
          <Separator orientation="vertical" className={'mx-1 h-5'} />
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <span
                  className={cn(
                    'text-sm ',
                    collection && isCollectionPage(router)
                      ? 'cursor-pointer text-muted-foreground hover:text-foreground'
                      : 'text-foreground'
                  )}
                  onClick={() => {
                    isCollectionPage(router) &&
                      router.push(ROUTES.COLLECTIONS.replace('[wsId]', wsId));
                  }}
                >
                  Collections
                </span>
              </BreadcrumbItem>
              {isCollectionPage(router) && collection && (
                <>
                  <BreadcrumbSeparator />
                  <BreadcrumbItem>
                    <span className={'text-sm text-foreground'}>
                      {collection?.name}
                    </span>
                  </BreadcrumbItem>
                </>
              )}
            </BreadcrumbList>
          </Breadcrumb>
        </>
      )}
      {actions && (
        <div className={'flex items-center gap-1'}>
          {isCollectionsPage(router) && (
            <Button
              type="button"
              onClick={() => setCollectionOpen(true)}
              size={'sm'}
            >
              <Plus className={'h-4 w-4 mr-2'} />
              New collection
            </Button>
          )}
          {isCollectionPage(router) && (
            <>
              <Button
                variant="ghost"
                size={'icon'}
                onClick={() => {
                  setCollectionOpen(true);
                  setCollectionRef(collection.ref);
                }}
                className={'rounded-full'}
              >
                <Bolt className={'h-4 w-4'} />
              </Button>
              <Separator orientation="vertical" className={'mx-1 h-5'} />
              <AlertDialog>
                <AlertDialogTrigger asChild>
                  <Button
                    variant="ghost"
                    size="icon"
                    className={'rounded-full'}
                    style={{ color: colors.eurykaRed }}
                  >
                    <Archive className={'h-4 w-4'} />
                  </Button>
                </AlertDialogTrigger>
                <AlertDialogContent>
                  <AlertDialogHeader>
                    <AlertDialogTitle>Archive collection</AlertDialogTitle>
                    <AlertDialogDescription>
                      {_.sample([
                        'Time Capsule Zone',
                        'Vintage Vault',
                        'Time Travel Toggle',
                        'Relic Roundup',
                      ])}
                    </AlertDialogDescription>
                  </AlertDialogHeader>
                  <AlertDialogFooter>
                    <AlertDialogCancel>Cancel</AlertDialogCancel>
                    <AlertDialogAction
                      onClick={() => {
                        updateCollection(collection.ref, {
                          isArchive: true,
                        });
                        router.push(ROUTES.COLLECTIONS.replace('[wsId]', wsId));
                      }}
                    >
                      Continue
                    </AlertDialogAction>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog>
            </>
          )}
        </div>
      )}
    </>
  );
};
export default CollectionsMenu;
