import { Component, LayoutGrid, Plus, Rows3 } from 'lucide-react';
import React from 'react';
import { useLocalStorage } from 'usehooks-ts';

import useCommon from 'hooks/useCommon';
import { useGlobalStore } from 'store';
import { isCharactersPage } from 'utils/routes';

import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';

const CharactersMenu = () => {
  const { router } = useCommon();
  const { setCharacterOpen } = useGlobalStore();

  const [charactersView, setCharactersView] = useLocalStorage(
    'charactersView',
    'rows'
  );

  if (!isCharactersPage(router)) return <></>;
  return (
    <div className={'flex items-center gap-1'}>
      <Button type="button" onClick={() => setCharacterOpen(true)} size={'sm'}>
        <Plus className={'h-4 w-4 mr-2'} />
        New character
      </Button>
      <Separator orientation="vertical" className={'mx-1 h-5'} />
      <Button
        variant={charactersView === 'groups' ? 'secondary' : 'ghost'}
        size="icon"
        className={'rounded-full'}
        onClick={() => setCharactersView('groups')}
      >
        <Component className={'h-4 w-4'} />
      </Button>
      <Button
        variant={charactersView === 'grid' ? 'secondary' : 'ghost'}
        size="icon"
        className={'rounded-full'}
        onClick={() => setCharactersView('grid')}
      >
        <LayoutGrid className={'h-4 w-4'} />
      </Button>
      <Button
        variant={charactersView === 'rows' ? 'secondary' : 'ghost'}
        size="icon"
        className={'rounded-full'}
        onClick={() => setCharactersView('rows')}
      >
        <Rows3 className={'h-4 w-4'} />
      </Button>
    </div>
  );
};
export default CharactersMenu;
