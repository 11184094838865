import {
  collection,
  doc,
  getDoc,
  getDocs,
  or,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from 'firebase/firestore';
import create from 'zustand';

import { db } from 'utils/api/firebase';
import { superAdmins } from 'utils/config';

import { TODO } from './global.types';
import { User } from './users.types';

import { toast } from '@/components/ui/use-toast';

export interface UsersStoreType {
  users: User[];
  syncUser: (user: User) => void;
  getUser: (id: string, forceUpdate?: boolean) => TODO;
  updateUser: (uid: string, data: TODO) => void;
  getWsRole: (
    wsId: string,
    uid: string
  ) => Promise<'primaryWsOwner' | 'owner' | 'editor' | 'reader'>;
  getWsPrivilege: (wsId: string, uid: string) => TODO;
}

const useUsersStore = create<UsersStoreType>((set, get) => ({
  users: [],
  syncUser: user => {
    set(({ users }) => {
      const userIndex = users.findIndex(t => t.id === user.id);
      if (userIndex === -1) return { users: [...users, user] };
      const updatedUsers = users.map((t, index) =>
        index === userIndex ? { ...t, ...user } : t
      );
      return {
        users: updatedUsers,
      };
    });
  },
  // id - (uid / email)
  getUser: async (id, forceUpdate = false) => {
    const { users, syncUser } = get();
    const user = users.find(u => u.id === id || u.email === id);
    if (user && !forceUpdate) return user;
    const q = query(
      collection(db, 'users'),
      or(where('user.email', '==', id), where('user.uid', '==', id))
    );
    const documentSnapshots = await getDocs(q);
    let userObj = {};
    documentSnapshots.forEach(doc => {
      const { user, organization, members = [], workspaces = [] } = doc.data();
      userObj = {
        id: user.uid,
        email: user?.email || '',
        name: user?.name || '',
        displayName: user?.displayName || '',
        photoURL: user?.photoURL || '',
        role: user?.role || '',
        industry: user?.industry || '',
        organizationName: organization?.name || '',
        organizationImageURL: organization?.imageURL || '',
        isOnboard: user?.isOnboard || false,
        members,
        workspaces,
      };
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      syncUser(userObj);
    });
    return userObj;
  },
  updateUser: async (uid, data) => {
    const { getUser } = get();
    // updates user data in the database
    try {
      const docRef = doc(db, 'users', uid);
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) throw new Error('User not found.');
      await updateDoc(docRef, {
        ...data,
        updatedAt: serverTimestamp(),
      });
      getUser(uid, true);
    } catch (error) {
      console.log('useUsersStore -- updateUser -- error:', error);
      toast({
        title: 'Something went wrong. Please try again.',
      });
    }
  },
  getWsRole: async (wsId, uid) => {
    const { getUser } = get();
    if (wsId === uid) return 'primaryWsOwner';
    const user = await getUser(uid);
    const { role } = user.workspaces.find(w => w.id === wsId) || {
      role: 'reader',
    };
    return role;
  },
  getWsPrivilege: (wsId, uid) => {
    const privilege = {
      role: 'unknown',
      canView: false, // can read workspace
      canModify: false, // can edit workspace / team / subscriptions
      canEdit: false, // can edit team
    };
    if (!wsId || !uid) return privilege;
    const { users, getUser } = get();
    const user = users.find(u => u.id === uid);
    if (!user) {
      getUser(uid);
      return privilege;
    }
    const role = user?.workspaces?.find(w => w.id === wsId)?.role || 'unknown';

    if (role === 'reader') {
      privilege.role = 'reader';
      privilege.canView = true;
      privilege.canModify = false;
      privilege.canEdit = false;
    }

    if (role === 'editor') {
      privilege.role = 'editor';
      privilege.canView = true;
      privilege.canModify = false;
      privilege.canEdit = true;
    }

    // super admins for quick access
    const isSuperAdmin = superAdmins.includes(uid);
    if (isSuperAdmin || role === 'owner' || wsId === uid) {
      privilege.role = isSuperAdmin
        ? 'superAdmin'
        : wsId === uid
        ? 'primaryOwner'
        : 'owner';
      privilege.canView = true;
      privilege.canModify = true;
      privilege.canEdit = true;
    }

    return privilege;
  },
}));

export default useUsersStore;
