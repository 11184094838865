import { Menu } from 'lucide-react';
import React, { useCallback } from 'react';
import { useLocalStorage, useWindowSize } from 'usehooks-ts';

import * as Styled from 'components/Header/Header.styles';
import LogoWithText from 'components/LogoWithText/LogoWithText';
import Sidebar, { SidebarProps } from 'components/Sidebar/Sidebar';
import useCommon from 'hooks/useCommon';
import { isAccountPage, ROUTES } from 'utils/routes';

import { Button } from '@/components/ui/button';
import { Sheet, SheetContent } from '@/components/ui/sheet';

export interface LayoutSidebar {
  uid?: string;
  sidebarProps?: Omit<SidebarProps, 'activeId'>;
}

const defaultProps: Partial<LayoutSidebar> = {};

const LayoutSidebar: React.FC<LayoutSidebar> = ({ uid, sidebarProps }) => {
  const { router, routeTo } = useCommon();

  const [isSidebarOverlayOpen, setSidebarOverlayOpen] = useLocalStorage(
    'isSidebarOverlayOpen',
    false
  );

  const { width } = useWindowSize();

  const onHomeClick = useCallback(() => {
    isAccountPage(router)
      ? routeTo(ROUTES.DASHBOARD, uid)
      : routeTo(ROUTES.HOME);
  }, [routeTo, router, uid]);

  return (
    <Sheet
      defaultOpen={false}
      open={width <= 1024 ? isSidebarOverlayOpen : false}
      onOpenChange={setSidebarOverlayOpen}
    >
      <SheetContent side="left" className={'p-0'}>
        <div
          className={
            'w-full border-b border-border/40 flex justify-between items-center h-14 px-5'
          }
        >
          <div className={'flex items-center gap-2'}>
            <Button
              variant="ghost"
              size="icon"
              className={'rounded-full'}
              onClick={() => setSidebarOverlayOpen(!isSidebarOverlayOpen)}
            >
              <Menu className={'h-5 w-5'} />
            </Button>
            <Styled.LogoWrapper onClick={onHomeClick}>
              <LogoWithText />
            </Styled.LogoWrapper>
          </div>
        </div>
        <div className={'mt-2'}>
          {sidebarProps && <Sidebar {...sidebarProps} />}
        </div>
      </SheetContent>
    </Sheet>
  );
};

LayoutSidebar.defaultProps = defaultProps;

export default LayoutSidebar;
