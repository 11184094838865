import { TODO } from './global.types';

export interface Imagination {
  id: string;
  [key: string]: TODO;
}

type ImaginationDefaults = {
  prompt: string;
  negativePrompt: string;
  aspectRatio: string;
  style?: string;
  // ideogram
  fileUrl?: string;
  fileWeight?: number;
  // luma
  enhancePrompt: boolean;
  isLoop: boolean;

  urls: {
    url: string;
    width: number;
    height: number;
    video?: {
      url: string;
    };
    audio?: {
      url: string;
    };
  }[];
  createdAt: TODO;
  updatedAt: TODO;
};

export type ImaginationIteration = ImaginationDefaults & {
  payloads: TODO[];
  previews: TODO[];
  type: string;
  typeVariant: string;
  revisedPrompts: string[];
};
