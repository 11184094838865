import React from 'react';

import { useGlobalStore } from 'store';

import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from '@/components/ui/sheet';

export interface LayoutNotifications {}

const defaultProps: Partial<LayoutNotifications> = {};

const LayoutNotifications: React.FC<LayoutNotifications> = () => {
  const { isNotificationsOpen, setNotificationsOpen } = useGlobalStore();

  return (
    <Sheet open={isNotificationsOpen} onOpenChange={setNotificationsOpen}>
      <SheetContent>
        <SheetHeader>
          <SheetTitle>Notifications</SheetTitle>
          <SheetDescription>No recent notifications</SheetDescription>
        </SheetHeader>
        <div className="grid gap-4 py-4"></div>
      </SheetContent>
    </Sheet>
  );
};

LayoutNotifications.defaultProps = defaultProps;

export default LayoutNotifications;
