/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

import SidebarEntry, { SidebarEntryProps } from './SidebarEntry/SidebarEntry';

import { Separator } from '@/components/ui/separator';
import { cn } from '@/lib/utils';

export interface SidebarProps {
  items: SidebarEntryProps[];
  onChange: SidebarEntryProps['onClick'];
  fullWidth?: boolean;
}

const defaultProps: Partial<SidebarProps> = {};

const Sidebar: React.FC<SidebarProps> = ({ items, onChange, fullWidth }) => {
  return (
    <nav className={cn('px-3 mt-2', fullWidth ? 'max-w-[310px]' : 'w-[230px]')}>
      <ul className={'space-y-1'}>
        {items.map((item, index) => (
          <li key={index}>
            {item.id === 'divider' ? (
              <div
                className={cn(
                  'flex items-center gap-2 mb-2',
                  index === 0 ? '' : 'mt-3'
                )}
              >
                {item.label && item.label.toLowerCase() !== 'divider' && (
                  <span
                    className={
                      'text-xs whitespace-nowrap text-muted-foreground'
                    }
                  >
                    {item.label}
                  </span>
                )}
                <Separator className={'shrink'} />
              </div>
            ) : (
              <SidebarEntry
                {...item}
                hasSubItems={item.subItems?.length > 0}
                isActive={item.isActive}
                {...(item.disabled
                  ? {
                      disabled: true,
                    }
                  : {
                      onClick: onChange,
                    })}
              />
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
};

Sidebar.defaultProps = defaultProps;

export default Sidebar;
