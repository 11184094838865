import { TooltipTrigger } from '@radix-ui/react-tooltip';
import { Construction, Plus } from 'lucide-react';
import React from 'react';

import useCommon from 'hooks/useCommon';
import {
  isFlipbookPage,
  isFlipbooksPage,
  isPlaygroundsPage,
  ROUTES,
} from 'utils/routes';

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';
import { Tooltip, TooltipContent } from '@/components/ui/tooltip';
import { cn } from '@/lib/utils';

const PlaygroundsMenu = (props: {
  breadcrumbs?: boolean;
  actions?: boolean;
}) => {
  const { router, wsId } = useCommon();

  const { breadcrumbs } = props;

  if (
    !isPlaygroundsPage(router) &&
    !isFlipbooksPage(router) &&
    !isFlipbookPage(router)
  )
    return <></>;

  return (
    <>
      {breadcrumbs && (
        <>
          <Separator orientation="vertical" className={'mx-1 h-5'} />
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <span
                  className={cn(
                    'text-sm ',
                    isFlipbooksPage(router) || isFlipbookPage(router)
                      ? 'cursor-pointer text-muted-foreground hover:text-foreground'
                      : 'text-foreground'
                  )}
                  onClick={() => {
                    router.push(ROUTES.PLAYGROUND.replace('[wsId]', wsId));
                  }}
                >
                  Playground
                </span>
              </BreadcrumbItem>
              {(isFlipbooksPage(router) || isFlipbookPage(router)) && (
                <>
                  <BreadcrumbSeparator />
                  <BreadcrumbItem>
                    <span
                      className={cn(
                        'text-sm ',
                        isFlipbookPage(router)
                          ? 'cursor-pointer text-muted-foreground hover:text-foreground'
                          : 'text-foreground'
                      )}
                      onClick={() => {
                        router.push(ROUTES.FLIPBOOKS.replace('[wsId]', wsId));
                      }}
                    >
                      Flipbooks
                    </span>
                  </BreadcrumbItem>
                </>
              )}
            </BreadcrumbList>
          </Breadcrumb>
        </>
      )}
      {props.actions && (
        <Tooltip delayDuration={100}>
          <TooltipTrigger asChild>
            <div>
              <Button
                onClick={() => {
                  //
                }}
                size={'sm'}
                disabled
              >
                <Plus className={'h-4 w-4 mr-2'} />
                New Flipbook
              </Button>
            </div>
          </TooltipTrigger>
          <TooltipContent>
            <Construction className={'w-4 h-4'} />
          </TooltipContent>
        </Tooltip>
      )}
    </>
  );
};
export default PlaygroundsMenu;
