export enum ColorNames {
  white = 'white',
  black = 'black',
  alto = 'alto',
  pink = 'pink',
  blueChalk = 'blueChalk',
  pattensBlue = 'pattensBlue',
  cinderella = 'cinderella',
  tourchRed = 'tourchRed',
  amber = 'amber',
  dogerBlue = 'dogerBlue',
  tenn = 'tenn',
  blue = 'blue',
  blue2 = 'blue2',
  purplePizzazz = 'purplePizzazz',
  green = 'green',
  stormGray = 'stormGray',
  eurykaRed = 'eurykaRed',
}

export enum FontNames {
  googleSans = 'googleSans',
  menlo = 'menlo',
}

const validFontCategories = [
  'heading1',
  'heading2',
  'body1',
  'body1Bold',
  'body2',
  'body2Bold',
  'body3',
  'body3Bold',
] as const;

export interface ResponsiveValues {
  mobile?: number;
  tablet?: number;
  desktop?: number;
}

export interface FontCategory {
  fontFamily?: FontNames | Record<string, FontNames>;
  fontSize: ResponsiveValues;
  lineHeight?: ResponsiveValues;
  fontWeight: number;
  letterSpacing?: number;
  wordSpacing?: number;
  fontStretch?: 'expanded' | 'normal';
  textDecoration?: 'underline' | 'none';
  textTransform?:
    | 'none'
    | 'capitalize'
    | 'uppercase'
    | 'full-size-kana'
    | 'full-width'
    | 'lowercase';
  fontStyle?: 'normal' | 'italic' | 'oblique' | 'inherit' | 'initial' | 'unset';
}

export interface Theme {
  colors: Record<ColorNames, string>;
  fonts: {
    face: Record<FontNames, string>;
    scale: Record<typeof validFontCategories[number], FontCategory>;
  };
  layout: typeof layout;
}

// "Name that Color" names
export const colors: Theme['colors'] = {
  [ColorNames.white]: '#ffffff',
  [ColorNames.black]: '#000000',
  [ColorNames.alto]: '#dddddd',
  [ColorNames.pink]: '#ff0080',
  [ColorNames.blueChalk]: '#F3E8FF',
  [ColorNames.pattensBlue]: '#DBEAFE',
  [ColorNames.cinderella]: '#FEE2E2',
  [ColorNames.tourchRed]: '#FF0E4C',
  [ColorNames.amber]: '#FEC100',
  [ColorNames.dogerBlue]: '#0A99FF',
  [ColorNames.tenn]: '#D45202',
  [ColorNames.blue]: '#1816FF',
  [ColorNames.blue2]: '#3D00EC',
  [ColorNames.purplePizzazz]: '#CC00CB',
  [ColorNames.green]: '#0DBA00',
  [ColorNames.stormGray]: '#6A6D85',
  [ColorNames.eurykaRed]: '#FF121B',
} as const;

export const fonts: Theme['fonts'] = {
  face: {
    [FontNames.googleSans]:
      "'Google Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
    [FontNames.menlo]:
      'Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace',
  },
  scale: {
    // Based on Material design typography scale, adjust as needed
    // fontSize: rem, letterSpacing: em
    // fontFamily: { [localeCode]: localeSpecificFont, default: defaultFont }
    heading1: {
      fontFamily: { default: FontNames.googleSans },
      fontSize: { mobile: 55, tablet: 89 },
      lineHeight: { mobile: 1, tablet: 1 },
      fontWeight: 400,
    },
    heading2: {
      fontFamily: { default: FontNames.googleSans },
      fontSize: { mobile: 24 },
      lineHeight: { mobile: 1.2 },
      fontWeight: 700,
    },
    body1: {
      fontFamily: { default: FontNames.googleSans },
      fontSize: { mobile: 18 },
      lineHeight: { mobile: 1.2 },
      fontWeight: 400,
    },
    body1Bold: {
      fontFamily: { default: FontNames.googleSans },
      fontSize: { mobile: 18 },
      lineHeight: { mobile: 1.2 },
      fontWeight: 700,
    },
    body2: {
      fontFamily: { default: FontNames.googleSans },
      fontSize: { mobile: 16 },
      lineHeight: { mobile: 1.2 },
      fontWeight: 400,
    },
    body2Bold: {
      fontFamily: { default: FontNames.googleSans },
      fontSize: { mobile: 16 },
      lineHeight: { mobile: 1.2 },
      fontWeight: 700,
    },
    body3: {
      fontFamily: { default: FontNames.googleSans },
      fontSize: { mobile: 12 },
      lineHeight: { mobile: 1.2 },
      fontWeight: 400,
    },
    body3Bold: {
      fontFamily: { default: FontNames.googleSans },
      fontSize: { mobile: 12 },
      lineHeight: { mobile: 1.2 },
      fontWeight: 700,
    },
  },
} as const;

// Comment unit where applicable
export const layout = {
  layoutMaxWidth: 1440, // px
  borderRadius: 14, // rem
  lineOpacity: 0.1,
  zIndex: {
    modals: 2,
    languageSelector: 3,
    nonFunctionals: 20,
    contextMenu: 30,
  },
  button: {
    small: { h: 30, mh: 25 },
    default: { h: 35, mh: 25 },
  }, // rem
  dropdown: {
    default: { h: 48 },
  }, // rem
  input: {
    padding: {
      inline: 10, // rem
      block: 10, // rem
    },
  },
  iconPadding: 4, // rem
  header: { h: 80 }, // rem
  headerPadding: { h: 10, w: 30 }, // rem
  sidebar: { w: 'max(14vw, 230rem)' },
  pagePadding: {
    h: 40, // rem
    w: 12.5, // vw
  },
} as const;

const theme: Theme = {
  colors,
  fonts,
  layout,
} as const;

export default theme;
