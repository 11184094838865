import { collection, doc, query, where } from 'firebase/firestore';
import { useMemo } from 'react';
import { useCollection, useDocumentData } from 'react-firebase-hooks/firestore';

import { useGlobalStore } from 'store';
import useBrandsStore from 'store/brands';
import useCollectionsStore from 'store/collections';
import useImaginationsStore, {
  imaginationsCollection,
} from 'store/imaginations';
import useProjectsStore from 'store/projects';
import useThreadsStore from 'store/threads';
import useUsersStore from 'store/users';
import useVoiceoversStore from 'store/voiceovers';
import useVoicesStore from 'store/voices';
import { db } from 'utils/api/firebase';

const useWorkspace = (props: { wsId: string }) => {
  const { wsId } = props;
  const { getUser } = useUsersStore();
  const { timestamp } = useGlobalStore();
  const { syncBrand } = useBrandsStore();
  const { syncProject } = useProjectsStore();
  const { syncCollection } = useCollectionsStore();
  const { syncImagination } = useImaginationsStore();
  const { syncThread } = useThreadsStore();
  const { syncVoice } = useVoicesStore();
  const { syncVoiceover } = useVoiceoversStore();

  const [wsDocData] = useDocumentData(doc(db, 'users', wsId), {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  const [brands] = useCollection(
    query(
      collection(db, 'users', wsId, 'brands'),
      where('updatedAt', '>', timestamp)
    )
  );
  const [projects] = useCollection(
    query(
      collection(db, 'users', wsId, 'projects'),
      where('updatedAt', '>', timestamp)
    )
  );
  const [collections] = useCollection(
    query(
      collection(db, 'users', wsId, 'collections'),
      where('updatedAt', '>', timestamp)
    )
  );
  const [imaginations] = useCollection(
    query(
      collection(db, 'users', wsId, imaginationsCollection),
      where('updatedAt', '>', timestamp)
    )
  );
  const [imaginationsLiked] = useCollection(
    query(
      collection(db, 'users', wsId, imaginationsCollection),
      where('likedUpdatedAt', '>', timestamp)
    )
  );
  const [imaginationsProjects] = useCollection(
    query(
      collection(db, 'users', wsId, imaginationsCollection),
      where('projectsUpdatedAt', '>', timestamp)
    )
  );
  const [imaginationsCollections] = useCollection(
    query(
      collection(db, 'users', wsId, imaginationsCollection),
      where('collectionsUpdatedAt', '>', timestamp)
    )
  );
  const [threads] = useCollection(
    query(
      collection(db, 'users', wsId, 'threads'),
      where('updatedAt', '>', timestamp)
    )
  );
  const [voices] = useCollection(
    query(
      collection(db, 'users', wsId, 'voices'),
      where('updatedAt', '>', timestamp)
    )
  );
  const [voiceovers] = useCollection(
    query(
      collection(db, 'users', wsId, 'voiceovers'),
      where('updatedAt', '>', timestamp)
    )
  );
  useMemo(() => {
    if (!brands) return;
    brands.docs.forEach(doc => {
      syncBrand({ id: doc.id, ref: doc.ref, ...doc.data() });
    });
  }, [brands, syncBrand]);
  useMemo(() => {
    if (!projects) return;
    projects.docs.forEach(doc => {
      syncProject({ id: doc.id, ref: doc.ref, ...doc.data() });
    });
  }, [projects, syncProject]);
  useMemo(() => {
    if (!collections) return;
    collections.docs.forEach(doc => {
      syncCollection({ id: doc.id, ref: doc.ref, ...doc.data() });
    });
  }, [collections, syncCollection]);
  useMemo(() => {
    if (imaginations)
      imaginations.docs.forEach(doc => {
        syncImagination({ id: doc.id, ref: doc.ref, ...doc.data() });
      });
    if (imaginationsLiked)
      imaginationsLiked.docs.forEach(doc => {
        syncImagination({ id: doc.id, ref: doc.ref, ...doc.data() });
      });
    if (imaginationsProjects)
      imaginationsProjects.docs.forEach(doc => {
        syncImagination({ id: doc.id, ref: doc.ref, ...doc.data() });
      });
    if (imaginationsCollections)
      imaginationsCollections.docs.forEach(doc => {
        syncImagination({ id: doc.id, ref: doc.ref, ...doc.data() });
      });
  }, [
    imaginations,
    imaginationsLiked,
    imaginationsProjects,
    imaginationsCollections,
    syncImagination,
  ]);
  useMemo(() => {
    if (!threads) return;
    threads.docs.forEach(doc => {
      syncThread({ id: doc.id, ref: doc.ref, ...doc.data() });
    });
  }, [threads, syncThread]);
  useMemo(() => {
    if (!voices) return;
    voices.docs.forEach(doc => {
      syncVoice({ id: doc.id, ref: doc.ref, ...doc.data() });
    });
  }, [voices, syncVoice]);
  useMemo(() => {
    if (!voiceovers) return;
    voiceovers.docs.forEach(doc => {
      syncVoiceover({ id: doc.id, ref: doc.ref, ...doc.data() });
    });
  }, [voiceovers, syncVoiceover]);

  useMemo(() => {
    getUser(wsId, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUser, wsId, wsDocData]);

  return {};
};

export default useWorkspace;
